import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import {FilterHeader} from "../Filter/FilterHeader";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { FilterDateRange } from "../Filter/FilterDateRange";
import dayjs from "dayjs";
import { reverseDate } from "../../helpers/reverseDate";
import { filterFunction } from "../../helpers/filterFunction";

interface IPaymentSettlementItem {
  id: number;
  date: string;
  number: string;
  contract: string;
  type: string;
  amount: string;
  purpose: string;
  contract_id: string;
}

const paymentSettlementItems: IPaymentSettlementItem[] = [
  {
    id: 1,
    date: "29.07.2024",
    number: "8078",
    contract: "Договор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018",
    type: "Виза",
    amount: "139 381 209,64",
    purpose: "Не следует, однако, забывать, что высокотехнологичная",
    contract_id: "1",
  },
  {
    id: 2,
    date: "28.07.2024",
    number: "8078",
    contract: "Договор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018",
    type: "Доп",
    amount: "139 381 209,64",
    purpose: "Не следует, однако, забывать, что высокотехнологичная",
    contract_id: "1",
  },
  {
    id: 3,
    date: "27.07.2024",
    number: "8078",
    contract: "Договор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018",
    type: "Тест",
    amount: "139 381 209,64",
    purpose: "Не следует, однако, забывать, что высокотехнологичная",
    contract_id: "1",
  },
];

const columnHelper = createColumnHelper<IPaymentSettlementItem>();

const DownloadCell = () => (
  <Link color="#1677FF">Скачать</Link>
);

export function PaymentSettlementsTable() {

  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: (info) => FilterDateRange(info.column, info.table, "Дата платежа"),
        enableColumnFilter: true,
        filterFn: (row, id, filterValues) => { 
          const date = new Date(dayjs(reverseDate(row.original.date)).format("YYYY-MM-DD"));
          const startDate = filterValues[0] ? filterValues[0] : '';
          const endDate = filterValues[1] ? filterValues[1] : ''; 
          return date >= startDate && date <= endDate;
        },
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("number", {
        header: "Номер платежа",
      }),
      columnHelper.accessor("contract", {
        header: "Договор",
        cell: (info) => ContractCell(info, info.cell.row.original.contract_id),
        enableColumnFilter: true,
      }),
      columnHelper.accessor("type", {
        header: (info) => FilterHeader(info.column, info.table,  "Тип"),
        cell: (info) =>  StatusCellTable(info.renderValue(), "#AAE2DB"),
        enableColumnFilter: true,
        filterFn: ({ original: { type } }, id, filterValues) =>
          filterFunction({ value: type, filterValues }),
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
      }),
      columnHelper.accessor("purpose", {
        header: "Назначение платежа",
      }),
      {
        header: "Скачать",
        id: "download",
        cell: DownloadCell,
      },
    ],
    []
  );

  return (
    <Table minHeight="300px" data={paymentSettlementItems} columns={columns} dblClickDisabled={true} />
  );
}