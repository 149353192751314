import { ITab } from "../../components/lib/tabs/Tabs";

export const reportPageTabs: ITab[] = [
    {
        id: 1,
        href: "execution-contracts",
        label: "Исполнение договоров",
    },
    { id: 2, href: "execution-estimates", label: "Исполнение смет" },
    {
        id: 3,
        href: "execution-lzk",
        label: "Исполнение по ЛЗК",
    },
];