import { IMark } from "../../components/RemarkTable/RemarksTable";

export const remarksTableList: IMark[] = Array(11).fill({
  id: 1,
  number: "С200-00294",
  date: "24.02.2023",
  status: "Открыто",
  buildObject: {
    name: "Испанские кварталы 2 очередь",
    manager: "Козлов Алексей Владимирович",
  },
})