import { ITab } from "../../components/lib/tabs/Tabs";

export const documentsInProgressPageTabs: ITab[] = [
  {
    id: 1,
    href: "completed-work-acts",
    label: "Акты выполненных работ",
    notice: 1,
    permission: ["contract_read", "contract_write"]
  },
  { id: 2, href: "invoices", label: "Счёт-фактуры", notice: 1, permission: "invoice_write" },
  { id: 3, href: "payment", label: "Оплата", permission: "payment_write" },
];
