import { Box, Flex, Text, Link } from "@chakra-ui/react";
import { INotificationItem } from "../../shared/mock/notificationData";

interface NotificationItemProps {
  item: INotificationItem;
}

export function NotificationItem(props: NotificationItemProps) {
  const { title, date, content, isImportant = false } = props.item;
  const [text, link, ...rest] = content.split("\n");

  return (
    <Flex
      direction="column"
      gap={1}
      p="16px 24px"
      borderBottom="1px solid #E3E7EF"
      position="relative"
    >
      <Flex>
        <Text fontWeight={500} lineHeight="20px" flex={1}>
          {title}
        </Text>
        <Text fontSize="12px" color="#8089A2" whiteSpace="nowrap">
          {date}
        </Text>
      </Flex>
      <Box fontSize="14px" lineHeight="18px">
        {text}
        <Link href="#" color="#2AB6A5">
          {link}
        </Link>
        {rest.join("")}
      </Box>
      {isImportant && (
        <Box w={1} h="full" position="absolute" bg="#E8363D" left={0} top={0} />
      )}
    </Flex>
  );
}
