import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper, Row,
} from "@tanstack/react-table";
import {
  Center,
  Flex, Image,
  Input,
  Modal, ModalBody,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text, Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { ILimitFenceCardMaterial, ILimitFenceCardMaterials } from "../../api/limitFenceCards/types";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import styles from "../lib/input/input.module.scss";
import tableStyles from "../lib/table/table.module.scss";
import calendar from "../../images/svg/calendar/calendar.svg";
import {ChakraModalProps} from "../../shared/types/modalProps";
import MainLogo from "../../images/svg/main-logo/main-logo.svg";
import {BlockTitle, Title} from "../lib/title/Title";
import {PrimaryButton} from "../lib/button/Button";
import SuccessLogo from "../../images/png/success.png";
import {addMaterialsToFenceCard, changeCurrentLimitFenceCard, fetchSingleLimitFenceCard, resetCurrentLimitFenceCard} from "../../store/slices/limitFenceCards";
import close from "../../images/svg/close/close.svg";
import { getSingleLimitFenceCardMaterials } from "../../api/limitFenceCards";

interface IMaterialsTableProps {
  searchValue?: string;
  setMaterialRow?: (material: ILimitFenceCardMaterial | undefined, index: number | undefined) => void;
  setDisableApprove?: (arg:boolean) => void;
  disabledChange?: boolean;
}

interface IEditModal extends ChakraModalProps {
  row?: Row<ILimitFenceCardMaterial>;
}

const columnHelper = createColumnHelper<ILimitFenceCardMaterial>();

const MeasureCell = (info: CellContext<ILimitFenceCardMaterial, string>) => (
  <Center>{info.renderValue()}</Center>
);

const CenterCell = (text: string) => <Center w="full">{text}</Center>;

const AmountCell = (info: CellContext<ILimitFenceCardMaterial, string>, setDisable: (arg:boolean) => void, setMaterialRow?: (material: ILimitFenceCardMaterial | undefined, index: number | undefined) => void, disabledChange?: boolean) => {
  const {row} = info;
  const dispatch = useAppDispatch();
  const {currentLimitFenceCard} = useAppSelector((state) => state.limitFenceCards);
  const [value, setValue] = useState(info.renderValue()!);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.)|\.*$/g, "");
    formatValue = formatValue === "" ? "0" : formatValue;
    setValue(formatValue);

    const index = row.index;
    if (index !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: ILimitFenceCardMaterial[] = [...currentLimitFenceCard?.materials];
      updatedMaterials[index] = {
        ...updatedMaterials[index],
        pivot: {
          ...updatedMaterials[index].pivot,
          amount: formatValue,
        },
      };

      if (row.getIsSelected() && setMaterialRow) {
        setMaterialRow(updatedMaterials[index], index);
      }

      const updatedLimitFenceCard = {...currentLimitFenceCard, materials: updatedMaterials};
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
    }
  }

  useEffect(() => {
    if (currentLimitFenceCard?.materials) {
      const hasChanges = currentLimitFenceCard.materials.some(
        (material, index) => material.pivot.amount !== info.row.original.pivot.amount && index === info.row.index
      );
      if (hasChanges) {
        setDisable(true);
      }
    }
  }, [currentLimitFenceCard?.materials, info.row.original.pivot.amount, info.row.index, setDisable]);

  useEffect(() => {
    setValue(info.renderValue()!)
  }, [info]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={`${styles.input_container} ${styles.table_input}`}>
          <input
            value={value}
            onChange={(e) => setValue(e.target.value.replace(/[^0-9.]/g, ""))}
            onBlur={handleBlur}
            onKeyDown={(e) => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
            onClick={(e) => e.stopPropagation()}
            disabled={disabledChange}
          />
        </div>
      </div>
    </div>
  );
};

const PeriodCell = (info: CellContext<ILimitFenceCardMaterial, string>) => {
  const inputStyle = `url(${calendar}) no-repeat 100% 50% content-box`;
  const isDisabled = Number(info.row.original.pivot.amount) === 0;
  const dispatch = useAppDispatch();
  const {currentLimitFenceCard} = useAppSelector((state) => state.limitFenceCards);
  const [value, setValue] = useState(isDisabled ? "" : info.row.original.pivot.period);

  useEffect(() => {
    setValue(isDisabled ? '' : info.row.original.pivot.period);
  }, [info.row.original.pivot.period, isDisabled]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.showPicker();
  }

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    const index = info.row.index;
    if (index !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: ILimitFenceCardMaterial[] = [...currentLimitFenceCard?.materials];
      updatedMaterials[index] = {
        ...updatedMaterials[index],
        pivot: {
          ...updatedMaterials[index].pivot,
          period: e.target.value,
        },
      };

      const updatedLimitFenceCard = {...currentLimitFenceCard, materials: updatedMaterials};
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
    }
  }

  return (
    <Input
      h="36px"
      rounded="md"
      type="date"
      borderColor="#E3E7EF"
      onFocus={handleFocus}
      bg={inputStyle}
      id="period"
      value={value}
      onChange={onDateChange}
      className={tableStyles.input_date}
      onClick={(e) => e.stopPropagation()}
      disabled={isDisabled}
    />
  )
}

const CommentCell = (info: CellContext<ILimitFenceCardMaterial, string>, onOpenCommentForm: (e: React.MouseEvent, row: Row<ILimitFenceCardMaterial>) => void) => {
  const isDisabled = Number(info.row.original.pivot.amount) === 0

  return (
    info.renderValue() ? (
      <Text noOfLines={2} onClick={(e: React.MouseEvent) => onOpenCommentForm(e, info.row)}>{info.renderValue()}</Text>
    ) : (
      <Center color={isDisabled ? "#AAB0C1" : "#2AB6A5"} cursor={isDisabled ? "auto" : "pointer"} onClick={(e: React.MouseEvent) => !isDisabled && onOpenCommentForm(e, info.row)} >Добавить</Center>
    )
  );
}

export function MaterialsTable({searchValue, setMaterialRow, setDisableApprove, disabledChange}: IMaterialsTableProps) {
  const dispatch = useAppDispatch();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const { currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);
  
  const [filteredMaterials, setFilteredMaterials] = useState(currentLimitFenceCard?.materials);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [row, setRow] = useState<Row<ILimitFenceCardMaterial>>();
  const [spinnerIsRunning, setSpinnerIsRuning] = useState(true)

  const onOpenCommentForm = useCallback((e: React.MouseEvent, row: Row<ILimitFenceCardMaterial>) => {
    e.stopPropagation();
    setRow(row);
    onOpen();
  }, [onOpen]);

  const onMaterialTableClick = (row: Row<ILimitFenceCardMaterial>) => {
    row.toggleSelected();
    setMaterialRow && setMaterialRow(row.getIsSelected() ? undefined : row.original, row.getIsSelected() ? undefined : row.index)
  }

  const setDisable = useCallback((arg:boolean) => {
    if (setDisableApprove) {
      setDisableApprove(arg);
    }
  },[setDisableApprove]);

  useEffect(() => {
    setFilteredMaterials(
      searchValue ?
        currentLimitFenceCard?.materials?.filter((item) =>
          item.material_name.toLowerCase().includes(searchValue.toLowerCase().trim())
        ) : currentLimitFenceCard?.materials
    )
  }, [searchValue, currentLimitFenceCard?.materials]);

  const fetchMaterialsPage = useCallback(async (page: number) => {
    return getSingleLimitFenceCardMaterials(Number(currentDirectionLetter?.limit_card_id), page).then(response => response.data.data);
  }, [currentDirectionLetter]);

  const loadMaterials = useCallback(async () => {
    const response = await fetchMaterialsPage(1);
    if (response) {
      let allMaterials: ILimitFenceCardMaterial[] = [];
      const totalPages = response.last_page;
      const allPromises: Promise<ILimitFenceCardMaterials>[] = [];

      for (let page = 1; page <= totalPages; page++) {
        allPromises.push(fetchMaterialsPage(page));
      }

      const pagesData = await Promise.all(allPromises);
      pagesData.forEach((pageData) => {
        allMaterials = allMaterials.concat(pageData.data);
      });
      dispatch(addMaterialsToFenceCard(allMaterials));
    }
  }, [dispatch, fetchMaterialsPage]);

  useEffect(() => {
    if (currentDirectionLetter?.limit_card_id) {
      dispatch(fetchSingleLimitFenceCard(currentDirectionLetter?.limit_card_id));
      loadMaterials();
    };
    setSpinnerIsRuning(false);

    return () => {
      dispatch(resetCurrentLimitFenceCard());
    }
  }, [dispatch, currentDirectionLetter, loadMaterials]);

  const columns = useMemo<Array<ColumnDef<ILimitFenceCardMaterial, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          columnHelper.accessor("pivot.order_number", {
            header: "№",
          }),
          columnHelper.accessor("ssom_order_number", {
            header: "№ ССОМ",
          }),
          columnHelper.accessor("material_name", {
            header: "Номенклатура",
          }),
          columnHelper.accessor("analog_material", {
            header: "Аналог номенклатуры",
          }),
          columnHelper.accessor("unit_of_measurement", {
            header: "Ед. изм.",
            cell: MeasureCell,
          }),
          columnHelper.accessor("indicative_price", {
            header: "Цена индикатива",
          }),
        ],
      }),

      columnHelper.group({
        id: "tender",
        header: () => CenterCell("Решение по тендеру"),
        columns: [
          columnHelper.accessor("tender_supplier", {
            header: "Поставщик",
          }),
          columnHelper.accessor("tender_quantity", {
            header: "Количество",
          }),
          columnHelper.accessor("tender_price", {
            header: "Цена",
          }),
          columnHelper.accessor("tender_solution", {
            header: "Решение по тендеру",
          }),
        ],
      }),
      columnHelper.group({
        id: "volume",
        header: () => CenterCell("Объёмы"),
        columns: [
          columnHelper.accessor("additional_total_volume", {
            header: "Общий",
          }),
          columnHelper.accessor("additional_selected_volume", {
            header: "Фактический",
          }),
          columnHelper.accessor("additional_selected_volume_with_unclosed_lzk", {
            header: "Выбранный ( с учетом незакрытых ЛЗК)",
          }),
          columnHelper.accessor("additional_unused_volume", {
            header: "Неизрасходованный",
          }),
          columnHelper.accessor("additional_unused_volume_with_unclosed_lzk", {
            header: "Неизрасходованный (с учетом незакрытых ЛЗК)",
          }),
          columnHelper.accessor("pivot.amount", {
            header: "Количество",
            meta: {cellClassName: "changeable"},
            cell: (info) => AmountCell(info, setDisable, setMaterialRow, disabledChange),
          }),
          columnHelper.accessor("pivot.period", {
            header: "Период поставки",
            meta: {cellClassName: "changeable"},
            cell: (info) => PeriodCell(info),
          }),
          columnHelper.accessor("pivot.comment", {
            header: "Комментарий подрядчика",
            cell: (info) => CommentCell(info, onOpenCommentForm),
            meta: {cellClassName: "changeable"}
          }),
        ],
      }),
      columnHelper.accessor("inventory_supervisor", {
        header: "Куратор ТМЦ",
      }),
    ],
    [onOpenCommentForm, setMaterialRow, setDisable]
  );

  return (
    <>
      {spinnerIsRunning ? (
        <Flex justifyContent="center">
          <Spinner alignSelf="center" size="xl" />
        </Flex>
      ) : (
        <>
          <Table
            data={filteredMaterials ?? []}
            columns={columns}
            rowClick={onMaterialTableClick}
            dblClickDisabled={true}
          />
          <EditModal isOpen={isOpen} onClose={onClose} row={row} />
        </>
      )}
    </>
  );
}

function EditModal(props: IEditModal) {
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const {onClose, isOpen, row} = props;

  const [localComment, setLocalComment] = useState(row?.original.pivot?.comment ? row?.original.pivot?.comment : "");

  useEffect(() => {
    setLocalComment(row?.original.pivot?.comment ? row?.original.pivot?.comment : "");
  }, [row]);

  const dispatch = useAppDispatch();

  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();

  const handleCommentModalClose = () => {
    if (row) {
      const index = row.index;
      if (index !== undefined && currentLimitFenceCard?.materials) {
        const updatedMaterials: ILimitFenceCardMaterial[] = [...currentLimitFenceCard?.materials];
        updatedMaterials[index] = {
          ...updatedMaterials[index],
          pivot: {
            ...updatedMaterials[index].pivot,
            comment: localComment ?? "",
          },
        };

        const updatedLimitFenceCard = {...currentLimitFenceCard, materials: updatedMaterials};
        dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
      }

      onClose();
      onSuccessOpen();
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
          <ModalHeader p={0}>
            <Image cursor="pointer" src={close} float="right" onClick={onClose} />
            <Flex direction="column" align="center" gap="24px" pb="8px">
              <Image src={MainLogo} boxSize="64px" alt="Main Logo" />
              <Title textAlign="center">Комментарий</Title>
            </Flex>
          </ModalHeader>
          <ModalBody p={0}>
            <Textarea h="360px" value={localComment} onChange={(e) => {setLocalComment(e.target.value)}}/>
          </ModalBody>
          <ModalFooter p={0}>
            <PrimaryButton
              w="full"
              fontSize="16px"
              onClick={handleCommentModalClose}
            >
              Сохранить
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessModal isOpen={isSuccessOpen} onClose={onSuccessClose} />
    </>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>) {
  const {isOpen, onClose} = props
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            <BlockTitle>Комментарий успешно изменён</BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
