import { Flex, Text } from "@chakra-ui/react"

interface InformationBlockProps {
    title: string;
    count: string;
}

export const InformationBlock = ({ title, count }: InformationBlockProps) => {
    return (
        <Flex gap="8px" flexDirection="column">
            <Text lineHeight="16px" fontSize="12px">{title}</Text>
            <Text fontWeight={400} lineHeight="44px" fontSize="34px">{count}</Text>
        </Flex>
    )
} 