import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { deleteStaff, createStaff, editStaff, getStaff } from "../../api/staff";
import { getRoleList } from "../../api/permissions";
import { getProfile } from "../../api/profile";
import { isError } from "..";
import { ICommonResponse } from "../../api/commonResponseType";
import { IProfile, IProfileResponse } from "../../api/profile/types";
import { IRole } from "../../api/permissions/types";
import {
  ICreateStaffResponse,
  IEditStaffResponse,
  IGetStaffData,
  IGetStaffResponse,
} from "../../api/staff/types";
import { ErrorType } from "../../shared/types/storeTypes";

export interface ProfileState {
  profileData: {
    profile: IProfile | null;
    permissions: string[] | null;
    roleList: IRole[] | null;
    isLoading: boolean;
    currentUser: IGetStaffData | null;
    isSidebarClosed: boolean;
    error?: string | null;
  };
}

const initialState: ProfileState = {
  profileData: {
    profile: null,
    permissions: null,
    roleList: null,
    isLoading: false,
    currentUser: null,
    isSidebarClosed: false,
    error: null,
  },
};

export const fetchProfile = createAsyncThunk<
  IProfileResponse,
  undefined,
  { rejectValue: string }
>("profile/fetchData", async (_, { rejectWithValue }) => {
  try {
    const { data } = await getProfile();

    return data;
  } catch (error: unknown) {
    console.error(error);

    throw rejectWithValue("Не удалось получить данные");
  }
});

export const fetchUser = createAsyncThunk<
  IGetStaffResponse,
  number,
  { rejectValue: string }
>("profile/fetchUser", async (id, { rejectWithValue }) => {
  try {
    const { data } = await getStaff(id);

    return data;
  } catch (error: unknown) {
    console.error(error);

    throw rejectWithValue("Не удалось получить данные");
  }
});

export const addUser = createAsyncThunk<
  ICreateStaffResponse,
  FormData,
  { rejectValue: AxiosError }
>("profile/addUser", async (params, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await createStaff(params);
    dispatch(fetchProfile());

    return data;
  } catch (error: unknown) {
    return rejectWithValue(error as AxiosError);
  }
});

export const editUser = createAsyncThunk<
  IEditStaffResponse,
  { params: FormData; id: number },
  { rejectValue: AxiosError }
>("profile/editUser", async ({ params, id }, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await editStaff(params, id);
    dispatch(fetchProfile());

    return data;
  } catch (error: unknown) {
    console.error(error);
    throw rejectWithValue(error as AxiosError);
  }
});

export const removeUser = createAsyncThunk<
  ICommonResponse,
  number,
  { rejectValue: string }
>("profile/removeUser", async (id, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await deleteStaff(id);
    dispatch(fetchProfile());

    return data;
  } catch (error: unknown) {
    console.error(error);
    throw rejectWithValue("Не удалось удалить сотрудника");
  }
});

export const fetchRoles = createAsyncThunk<
  IRole[],
  undefined,
  { rejectValue: string }
>("profile/fetchRoles", async (_, { rejectWithValue }) => {
  try {
    const { data } = await getRoleList();

    return data.data.roles;
  } catch (error: unknown) {
    console.error(error);

    throw rejectWithValue("Не удалось получить данные");
  }
});

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfile(state) {
      state.profileData.profile = null;
    },
    resetError(state) {
      state.profileData.error = null;
    },
    resetCurrentUser(state) {
      state.profileData.currentUser = null;
    },
    changeSidebarPosition(state, action) {
      state.profileData.isSidebarClosed = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.profileData.isLoading = true;
        state.profileData.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileData.isLoading = false;
        state.profileData.profile = action.payload.data;
      })

      .addCase(addUser.pending, (state) => {
        state.profileData.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state) => {
        state.profileData.isLoading = false;
      })

      .addCase(removeUser.pending, (state) => {
        state.profileData.isLoading = true;
      })
      .addCase(removeUser.fulfilled, (state) => {
        state.profileData.isLoading = false;
      })

      .addCase(fetchRoles.pending, (state) => {
        state.profileData.isLoading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.profileData.isLoading = false;
        state.profileData.roleList = action.payload;
      })
      .addCase(fetchUser.pending, (state) => {
        state.profileData.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.profileData.isLoading = false;
        state.profileData.currentUser = action.payload.data;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.profileData.isLoading = false;
        state.profileData.error = action.payload.response?.data.message;
      });
  },
});

export const profileReducer = profileSlice.reducer;
export const {
  resetProfile,
  resetError,
  resetCurrentUser,
  changeSidebarPosition,
} = profileSlice.actions;
