import { useFormik } from "formik";
import { PasswordInput } from "../../lib/input/PasswordInput";

export function ChangeEmailModal() {
  const onSubmit = () => {};

  const formik = useFormik({
    initialValues: {
      password_old: "",
      email: "",
    },

    onSubmit,
  });

  return (
    <>
      <PasswordInput
        placeholder="Введите текущий пароль"
        name="password_old"
        value={formik.values.password_old}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <PasswordInput
        placeholder="Введите новый e-mail"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </>
  );
}
