import {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import {useAppSelector} from "../../store";
import {FormikProps, useFormik} from "formik";
import * as Yup from "yup";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { addWorkCompletedAct, attachFilesToSingleWorkCompletedAct } from "../../store/slices/workCompletedActs";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import {
  OutlineMenuButton,
  PrimaryMenuButton,
} from "../../components/lib/menu/ChakraMenuButton";
import {
  ChakraMenuItem,
  ChakraMenuList,
} from "../../components/lib/menu/ChakraMenuList";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainTableBlock } from "../../components/CreateActBasedOnEstimateComponents/MainTableBlock/MainTableBlock";
import { CreateActInformationBlock } from "../../components/CreateActBasedOnEstimateComponents/CreateActInformationBlock";
import { BuildingObjectsBlock } from "../../components/CreateActBasedOnEstimateComponents/BuildingObjectsBlock/BuildingObjectsBlock";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { ICreateWorkCompletedActRequest, ICreateWorkCompletedActResponse } from "../../api/workCompletedActs/types";
import styles from "./create-act.module.scss";
import { Loader } from "../../components/Loader/Loader";
import {CloseFormModal} from "../../components/CloseFormModal/CloseFormModal";
import {SingleEstimateActPrintData} from "../../components/SingleEstimateActPrintData/SingleEstimateActPrintData";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { russianPatternCheck } from "../../helpers/russianPatternCheck";

enum ActStatuses {
  WorkingDocument = "Рабочий документ",
}

interface HeaderButtonGroupProps {
  activePage: number;
  changePage: () => void;
  onSubmit: (status: ActStatuses) => void;
  isLoading: boolean;
  closeLoading: (arg: boolean) => void;
  fieldsAreSet: () => boolean;
  onOpen: () => void;
  handleOpenFilesModal: () => void
}

export function CreateActBasedOnEstimatePage({ filesModal, setFilesModal, dispatch, goBack }: any) {
  const { currentEstimate, currentDetails } = useAppSelector((state) => state.estimates);
  const detailsWithQuantity = currentDetails.filter(detail => Number(detail.quantityinthedocument) !== 0);
  const { currentContract } = useAppSelector((state) => state.contracts)
  
  const [activePage, setActivePage] = useState(1);
  const [isRunSpinner, setIsRunSpinner] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangePage = () => {
    activePage === 1 ? setActivePage(2) : setActivePage(1);
  };

  const handleOpenFilesModal = () => {
    setFilesModal(true);
  }

  const fileAttach = (id: number) => {
    dispatch(attachFilesToSingleWorkCompletedAct({ id: id, files: files }))
      .then(() => goBack())
      .catch((e: Error) => {
        console.error("Error: ", e);
      });
  };

  useEffect( () => {
    if(!currentEstimate){
      const remove = "/create-act"
      goBack(location.pathname.slice(0, -remove.length))
    }
  }, [currentEstimate])

  const formik: FormikProps<ICreateWorkCompletedActRequest> =
    useFormik<ICreateWorkCompletedActRequest>({
      initialValues: {
        invoice_number: "",
        invoice_date: "",
        act_number: "",
        act_date: "",
        sf_number: "",
        sf_date: "",
        manager_name: "",
        manager_name_power_of_attorney: "",
        chief_accountant: "",
        chief_accountant_power_of_attorney: "",
        date_from: "",
        date_to: "",
        status: "",
        contract_id: currentContract?.id ?? 0,
        estimate_id: currentEstimate?.id ?? 0,
        details: detailsWithQuantity.map((detail) => ({
          ConstructionObjectID: detail.ConstructionObjectID,
          TypeofWork: detail.TypeofWork,
          quantity: Number(detail.quantityinthedocument),
          Price: Number(detail.Price),
          Total: Number(detail.Total),
        })),
      },
      validationSchema: Yup.object({
        invoice_number: Yup.string().required(),
        invoice_date: Yup.string().required(),
        act_number: Yup.string().required(),
        act_date: Yup.string().required(),
        sf_number: Yup.string().required(),
        sf_date: Yup.string().required(),
        manager_name: Yup.string().required(),
        manager_name_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
              Yup.string().required() : Yup.number().positive().nullable()
        ),
        chief_accountant: Yup.string().required(),
        chief_accountant_power_of_attorney: Yup.lazy((value) =>
            typeof value === 'string' ?
                Yup.string().required() : Yup.number().positive().nullable()
        ),
        date_from: Yup.string().required(),
        date_to: Yup.string().required(),
        status: Yup.string().required(),
        contract_id: Yup.number().required(),
        estimate_id: Yup.number().required(),
        details: Yup.array().required(),
      }),
      onSubmit: () => dispatch(addWorkCompletedAct(formik.values)),
      enableReinitialize: true,
      validateOnBlur: true,
      validateOnMount: true,
    });

  const fieldsAreSet = () => {
    const res = formik.values.invoice_number.trim() !== '' && formik.values.invoice_date !== '' &&
        formik.values.act_number.trim() !== '' && formik.values.act_date !== '' &&
        formik.values.sf_number.trim() !== '' && formik.values.sf_date !== '' &&
        formik.values.manager_name.trim() !== '' && formik.values.chief_accountant.trim() !== '' &&
        formik.values.date_from !== '' && formik.values.date_to !== '' && 
        russianPatternCheck(formik.values.manager_name) && russianPatternCheck(formik.values.chief_accountant);

    return res;

  }


  const onSubmit = (status: ActStatuses) => {
    if (currentContract && currentEstimate) {
      setIsRunSpinner(true);
      dispatch(
          addWorkCompletedAct({
            ...formik.values,
            status,
          })
      )
          .then((res: { payload: ICreateWorkCompletedActResponse; }) => { 
            if (!!files.length) {
              fileAttach((res.payload as ICreateWorkCompletedActResponse)?.data.id);
            } else {
              goBack();
            }
          })
          .catch((e: Error) => {
            console.error('Error: ', e);
          })
          .finally(() => {
            setIsRunSpinner(false);
          })
    }
  };


  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={10} padding="0 24px 24px 24px">
        <Flex direction="column">
          <Flex
            direction="column"
            gap={4}
            position="sticky"
            top={0}
            paddingTop="24px"
            zIndex={2}
            bg="#F6F8FC"
            pb={4}
          >
            <Breadcrumb
              fontSize={14}
              separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
            >
              <BreadcrumbItem>
                <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="/my-documents/contracts">Договоры</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href={`/my-documents/contracts/${currentContract?.id}`}>
                  {currentContract?.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage color="#8089A2">
                <BreadcrumbLink href={`/my-documents/contracts/${currentContract?.id}/create-act`}>
                  Создать акт на основе сметы
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Flex justify="space-between" align="center">
              <Flex align="center" gap={4}>
                {activePage === 1 && <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()} />}
                <BlockTitle>Создать акт на основе сметы</BlockTitle>
              </Flex>
              <HeaderButtonGroup
                handleOpenFilesModal={handleOpenFilesModal}
                activePage={activePage}
                changePage={handleChangePage}
                onSubmit={onSubmit}
                isLoading={isRunSpinner}
                closeLoading={setIsRunSpinner}
                fieldsAreSet={fieldsAreSet}
                onOpen={onOpen}
              />
            </Flex>
          </Flex>

          {activePage === 1 ? (
            <MainTableBlock />
          ) : (
            <SecondStage formik={formik} />
          )}
        </Flex>
      </Flex>
      <CloseFormModal isOpen={isOpen} onClose={onClose} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={[]}
        setFilesOnCreate={setFiles}
      />
    </MainLayout>
  );
}

const SecondStage = (props: {
  formik: FormikProps<ICreateWorkCompletedActRequest>;
}) => {
  return (
    <Flex direction="column" gap={4}>
      <Flex direction="column" gap={10}>
        <CreateActInformationBlock formik={props.formik} />
        <BuildingObjectsBlock />
        <SingleEstimateActPrintData />
      </Flex>
    </Flex>
  );
};

const HeaderButtonGroup = (props: HeaderButtonGroupProps) => {
  const { handleOpenFilesModal } = props;

  return (
    <ButtonGroup spacing={3}>
      {props.activePage === 1 ? (
        <>
          <Menu>
            <OutlineMenuButton rightIcon={<ChevronDownIcon />}>
              Excel
            </OutlineMenuButton>
            <ChakraMenuList
              list={[
                {
                  title: "Загрузить текущее выполнение",
                },
                {
                  title: "Выгрузить текущее выполнение",
                },
              ]}
            />
          </Menu>
          <PrimaryButton onClick={props.changePage}>Далее</PrimaryButton>
        </>
      ) : (
        <>
          <OutlineButton onClick={props.changePage}>Назад</OutlineButton>
          <OutlineButton onClick={props.onOpen}>Отмена</OutlineButton>
          <OutlineButton
            leftIcon={<Icon as={Document} fill={"#E8363D"} />}
            gap={2}
            onClick={handleOpenFilesModal}
          >
            Файлы
          </OutlineButton>
          {!props.isLoading && (
            <Menu>
              <PrimaryMenuButton
                rightIcon={<ChevronDownIcon />}
                className={props.fieldsAreSet() ? "" : styles.disabled}
              >
                Записать
              </PrimaryMenuButton>
              <MenuList border="1px solid #E3E7EF" p={1}>
                <Divider />
                <ChakraMenuItem
                  onClick={() => props.onSubmit(ActStatuses.WorkingDocument)}
                >
                  Записать рабочий документ
                </ChakraMenuItem>
              </MenuList>
            </Menu>
          )}

          {props.isLoading && <Loader isLoading={props.isLoading} />}

          
        </>
      )}
    </ButtonGroup>
  );
};
