import { useMemo } from "react";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Table } from "../lib/table/Table";
import {FilterHeader} from "../Filter/FilterHeader";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";
import { fetchRequirementsList } from "../../store/slices/requirements";
import { RootState, useAppSelector } from "../../store";
import { IRequirement } from "../../api/requirements/types";
import { formatAmount } from "../../helpers/formatAmount";

const columnHelper = createColumnHelper<IRequirement>();

const OrganizationCell = (info: CellContext<IRequirement, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

const DownloadCell = () => (
  <DownloadIcon cursor="pointer" w={5} h={5} color="#2AB6A5" />
);

export function RequirementsAccountTable() {
  const { requirementsList } = useAppSelector((state: RootState) => state.requirements);
  const columns = useMemo(
    () => [
      columnHelper.accessor("organization_name", {
        header: "Организация и проект",
        cell: OrganizationCell,
        enableColumnFilter: true,
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, info.cell.row.original.contract_id),
      }),
      columnHelper.accessor("type", {
        header: "Вид требований",
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table,  "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
      }),
      columnHelper.accessor("pay_before", {
        header: "Оплатить до",
        enableSorting: true,
      }),
      {
        header: "Файлы",
        id: "download",
        cell: DownloadCell,
      },
    ],
    []
  );

  return (
    <Table minHeight="300px" data={requirementsList} columns={columns} dblClickDisabled={true} fetchFunction={fetchRequirementsList} />
  );
}
