export interface INotificationItem {
  id: number;
  title: string;
  date: string;
  content: string;
  isImportant?: boolean;
}

export const notificationData: INotificationItem[] = [
  {
    id: 1,
    title:
      "Текст уведомления по доп. запросам должен иметь соответствующую конструкцию",
    date: "12.10.2023, 15:28",
    content:
      "Получен доп. запрос по документу \nДоговор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018\n от Читаишвили Зураб Тамазович",
    isImportant: true,
  },
  {
    id: 2,
    title:
      "Текст уведомления по доп. запросам должен иметь соответствующую конструкцию",
    date: "12.10.2023, 15:28",
    content: `Получен ответ по Вашему доп. запросу Акты допуска по документу \nДоговор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018\n:
        Акты допуска приложены к договору`,
    isImportant: true,
  },
  {
    id: 3,
    title: "Текст уведомления для подписания",
    date: "11.10.2023, 15:28",
    content: "Появился документ в Диадок на подписание. \nПерейти к документу",
  },
  {
    id: 4,
    title:
      "Текст уведомления по доп. запросам должен иметь соответствующую конструкцию",
    date: "11.10.2023, 15:28",
    content: `Получен ответ по Вашему доп. запросу Акты допуска по документу \nДоговор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018\n:
        Акты допуска приложены к договору`,
  },
  {
    id: 5,
    title: "Текст уведомления для “Неоплаченных”",
    date: "11.10.2023, 15:28",
    content: "Появилось новое требование по оплате. \nПерейти в раздел",
    isImportant: true,
  },
  {
    id: 6,
    title:
      "Текст уведомления по доп. запросам должен иметь соответствующую конструкцию",
    date: "10.10.2023, 15:28",
    content: `Получен ответ по Вашему доп. запросу Акты допуска по документу \nДоговор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018\n:
       Акты допуска приложены к договору`,
  },
  {
    id: 7,
    title:
      "Текст уведомления по доп. запросам должен иметь соответствующую конструкцию",
    date: "10.10.2023, 15:28",
    content: `Получен ответ по Вашему доп. запросу Акты допуска по документу \nДоговор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018\n:
       Акты допуска приложены к договору`,
  },
];
