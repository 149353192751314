import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { Files } from "../endpoints";

export const getFile = (
    id: string
): AxiosPromise<Blob> =>
    axiosInstance.get(
        Files.FILE + `/${id}`, {
        responseType: 'blob',
    }
    );


export const getRequestedFile = (id: string): AxiosPromise<Blob> =>
  axiosInstance.get(Files.REQUESTED_FILE + `/${id}`, {
    responseType: "blob",
  });

export const attachFiles = (url: string, files: File[]) => {
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files[]', file);
    });

    return axiosInstance.post(
        url,
        formData,
        {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        }
    );
};