import { Flex, Icon } from "@chakra-ui/react";
import { PrimaryButton } from "../lib/button/Button";
import { BlockTitle } from "../lib/title/Title";
import { PaymentTable } from "./PaymentTable";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { DownloadFilesModal } from "../Modal/ModalRight/DownloadFilesModal";
import ComponentWithProps from "../../helpers/ComponentWithProps";

export function PaymentBlock({ filesModal, setFilesModal }: any) {
  const UpdatedPaymentTable = ComponentWithProps(PaymentTable)

  return (
    <>
      <Flex direction="column" gap={4}>
        <Flex justify="space-between" align="center">
          <BlockTitle>Оплата</BlockTitle>
          <PrimaryButton onClick={() => setFilesModal(true)} leftIcon={<Icon as={Document} fill={"#fff"} />}>
            Файлы
          </PrimaryButton>
        </Flex>
        <UpdatedPaymentTable />
      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={[]}
        fileAttach={() => {}}
      />
    </>
  );
}
