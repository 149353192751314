import { Flex } from "@chakra-ui/react";
import { PrimaryButton } from "../lib/button/Button";
import { BlockTitle } from "../lib/title/Title";
import { IndemnityLetterTable } from "./IndemnityLetterTable";
import { ReactComponent as Print } from "../../images/svg/documents/print.svg";

export function IndemnityLetterBlock() {
  return (
    <Flex direction="column" gap={4}>
      <Flex justify="space-between" align="center">
        <BlockTitle>Гарантийные письма</BlockTitle>
        <PrimaryButton leftIcon={<Print />}>Печатать</PrimaryButton>
      </Flex>
      <IndemnityLetterTable />
    </Flex>
  );
}
