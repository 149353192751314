import { TextareaHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./textarea.module.scss";

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: boolean;
}

export function TextArea(props: TextAreaProps) {
  const { label = "", id, error, ...rest } = props;

  const textAreaClass = classNames(styles.container, {
    [styles.error]: error,
  });

  return (
    <div className={styles.wrapper}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={textAreaClass}>
        <textarea id={id} {...rest} />
      </div>
    </div>
  );
}
