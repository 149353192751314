import { useEffect, useState } from "react";
import { PrimaryButton } from "../lib/button/Button";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { ReportsDropdownList } from "./ReportsDropdownList";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { IReportDocument } from "../../api/reports/types";
import { fetchEstimateReport, fetchReportEstimateList } from "../../store/slices/reports";

export function ExecutionEstimatesComponent() {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [resultEstimate, setResultEstimate] = useState<
    IReportDocument
  >();

  const { estimatesList } = useAppSelector(
    (state: RootState) => state.report
  );

  const handleSendReport = () => {
    setIsOpenModal(true);
    dispatch(fetchEstimateReport({ estimate_id: resultEstimate?.id ? resultEstimate.id : null }))
      .finally(() => {
        setIsOpenModal(false)
      })
  }

  useEffect(() => {
    dispatch(fetchReportEstimateList())
  }, [dispatch])

  return (
    <>
      <ReportsDropdownList
        selectAllButtonText="Все сметы"
        returnResult={setResultEstimate}
        inputPlaceholder="Название документа"
        documents={estimatesList}
      />
      <PrimaryButton onClick={handleSendReport} w="165px">
        Сформировать
      </PrimaryButton>
      <FullScreenWaitingModal openModal={isOpenModal} />
    </>
  );
}
