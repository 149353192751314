import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IInvoice,
  IInvoicesResponse,
  ISingleInvoiceResponse,
} from "../../api/invoices/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import {IFilesAttachResponse} from "../../api/files/types";
import {AxiosError} from "axios";
import { attachFiles } from "../../api/files";
import { Files, InvoicesEndpoints } from "../../api/endpoints";

interface IInvoiceInitialState extends ICommonInitialState {
  invoices: IInvoice[];
  contractInvoices: IInvoice[];
  currentInvoice: IInvoice | null;

}

const initialState: IInvoiceInitialState = {
  invoices: [],
  contractInvoices: [],
  currentInvoice: null,
  isLoading: false,
  error: null,
};

export const fetchInvoices = createAsyncThunkInstance<
  IInvoicesResponse,
  undefined
>("invoices/fetchInvoices", api.invoices.getInvoices);

export const fetchInvoicesByContract = createAsyncThunkInstance<
  IInvoicesResponse,
  number
>("invoices/fetchInvoicesByContract", api.invoices.getInvoicesByContract);

export const fetchSingleInvoice = createAsyncThunkInstance<
  ISingleInvoiceResponse,
  string
>("invoices/fetchSingleInvoice", api.invoices.getSingleInvoice);

export const approveSingleInvoice = createAsyncThunkInstance<
  ISingleInvoiceResponse,
  string
>("invoices/approveSingleInvoice", api.invoices.approveInvoice);

export const attachFilesToSingleInvoice = createAsyncThunk<
  IFilesAttachResponse,
  { id: string; files: File[] },
  { rejectValue: AxiosError }
>(
  "limitFenceCards/attachFilesToSingleSavingsCompensationAct",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(InvoicesEndpoints.SINGLE_INVOICE + `/${id}` + Files.ATTACH_FILES,files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    resetContractInvoices(state) {
      state.contractInvoices = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoices = action.payload.data;
      })

      .addCase(fetchInvoicesByContract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchInvoicesByContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractInvoices = action.payload.data;
      })

      .addCase(fetchSingleInvoice.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentInvoice = action.payload.data;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const invoicesReducer = invoicesSlice.reducer;
export const { resetContractInvoices } = invoicesSlice.actions;
