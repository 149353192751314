import { Flex, Menu } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../lib/button/Button";
import { ChakraMenuList } from "../lib/menu/ChakraMenuList";
import { BlockTitle } from "../lib/title/Title";
import { ActsTable } from "./ActsTable";
import { actMenuList } from "../../shared/mock/menuListItems";
import {RootState, useAppSelector} from "../../store";
import { OutlineMenuButton } from "../lib/menu/ChakraMenuButton";

export function ActsBlock() {
  const { currentContractSingleWorkCompletedAct } = useAppSelector(
    (state) => state.contracts
  );
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );

  return (
    <Flex direction="column" gap={4} maxH="full" h="full">
      <BlockTitle>Акты</BlockTitle>
      <Flex gap="12px" >
        <PrimaryButton isDisabled={!currentContractSingleWorkCompletedAct || currentContract?.is_archived || currentContract?.status !== "Зарегистрирован"}>Создать на основании</PrimaryButton>
        <Menu>
          <OutlineMenuButton
            leftIcon={<DownloadIcon />}
            rightIcon={<ChevronDownIcon />}
            isDisabled={!currentContractSingleWorkCompletedAct}
          >
            Скачать
          </OutlineMenuButton>
          <ChakraMenuList list={actMenuList} />
        </Menu>
      </Flex>
      <ActsTable />
    </Flex>
  );
}
