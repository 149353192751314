import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {AccessActsEndpoints, ContractsEndpoints, WorkCompletedActsEndpoints} from "../endpoints";
import {
  IContractsResponse,
  ISingleContractResponse,
  ISingleContractWorkCompletedActsResponse,
} from "./types";
import {IAccessActsResponse} from "../accessActs/types";

export const getContracts = (): AxiosPromise<IContractsResponse> =>
  axiosInstance.get(ContractsEndpoints.CONTRACTS);

export const getArchiveContracts = (): AxiosPromise<IContractsResponse> =>
  axiosInstance.get(ContractsEndpoints.ARHIVE_CONTRACTS);

export const getSingleContract = (
  id: number
): AxiosPromise<ISingleContractResponse> =>
  axiosInstance.get(ContractsEndpoints.SINGLE_CONTRACT + `/${id}`);

export const getSingleContractWorkCompletedActs = (
  id: number
): AxiosPromise<ISingleContractWorkCompletedActsResponse> =>
  axiosInstance.get(
    ContractsEndpoints.SINGLE_CONTRACT +
      `/${id}/` +
      WorkCompletedActsEndpoints.WORK_COMPLETED_ACTS
  );

export const getSingleContractAccessActs = (
  id: number
): AxiosPromise<IAccessActsResponse> =>
  axiosInstance.get(
    ContractsEndpoints.SINGLE_CONTRACT +
    `/${id}` +
    AccessActsEndpoints.ACCESS_ACTS
  );
