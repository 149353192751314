import {InformationBlock} from "../InformationBlock/InformationBlock";
import {
  InformationItem, InformationItemInputGroup,
} from "../InformationBlock/InformationItem";
import {AppDispatch, RootState, useAppSelector} from "../../store";
import {StatusCell} from "../StatusCell/StatusCell";
import {InformationItemDateInput} from "../InformationBlock/InformationItemDateInput";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {ICreateLimitFenceCardRequest} from "../../api/limitFenceCards/types";
import {addSingleFenceCard} from "../../store/slices/limitFenceCards";
import {useDispatch} from "react-redux";
import {Accordion, AccordionButton, AccordionIcon, AccordionItem, Box, Link, useDisclosure} from "@chakra-ui/react";
import {CompensationActModal} from "../CompensationActModal/CompensationActModal";

interface ICreateStockCardInformationBlock {
  onChangeData: (period_from: string, period_to: string, contract_id: string) => void;
  setDisabled: (value: boolean) => void;
}

export function CreateStockCardInformationBlock({onChangeData, setDisabled}: ICreateStockCardInformationBlock) {
  const dispatch = useDispatch<AppDispatch>();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [canChangeData, setCanChangeData] = useState(!currentContract);

  const onSubmit = (values: ICreateLimitFenceCardRequest) => dispatch(addSingleFenceCard(values));

  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      contract_id: currentLimitFenceCard?.contract_id.toString() ?? currentContract?.id.toString() ?? "",
      period_from: "",
      period_to: "",
      comment: ""
    },
    onSubmit,
  });

  const {period_from, period_to, contract_id} = formik.values;

  useEffect(() => {
    setFieldValue("contract_id", currentLimitFenceCard ? currentLimitFenceCard?.contract_id.toString() : currentContract?.id.toString());
  }, [setFieldValue, currentLimitFenceCard, currentContract]);

  useEffect(() => {
    onChangeData(period_from, period_to, contract_id);
    setDisabled(period_from === "" || period_to === "" || (Date.parse(period_from) > Date.parse(period_to)));
  }, [period_from, period_to, contract_id, onChangeData, setDisabled]);

  return (
    <>
      <InformationBlock>
        <InformationItem title="Договор" isAccordion>
          {!canChangeData ?
            (
              <Link color="#2AB6A5"
                    href={`/my-documents/contracts/${currentLimitFenceCard ? currentLimitFenceCard.contract_id : currentContract?.id}`}>
                {currentContract?.name ?? currentLimitFenceCard?.contract_name}
              </Link>
            ) : (
              <Accordion color="#5C657E" border="1px solid #E3E7EF" borderRadius="8px">
                <AccordionItem>
                  <h2>
                    <AccordionButton onClick={onOpen}>
                      <Box as="span" flex='1' textAlign='left'>
                        {currentContract?.name ?? currentLimitFenceCard?.contract_name}
                      </Box>
                      <AccordionIcon/>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              </Accordion>
            )
          }

        </InformationItem>
        <InformationItem title="Статус">
          <StatusCell value=""/>
        </InformationItem>
        <InformationItem title="Номер">{currentContract ? "" : currentLimitFenceCard?.number}</InformationItem>
        <InformationItem title="Организация">
          {currentContract?.a101_org_name ?? currentLimitFenceCard?.a101_org_name}
        </InformationItem>
        <InformationItem title="Контрагент">{currentContract?.organization.org_name ?? currentLimitFenceCard?.organization_name}</InformationItem>
        <InformationItem title="Проект">{currentContract?.project?.name ?? currentLimitFenceCard?.project_name}</InformationItem>
        <InformationItem title="ССОМ">
          {currentContract ? "" : currentLimitFenceCard?.ssom}
        </InformationItem>
        <InformationItem title="Период">
          <InformationItemInputGroup>
            <InformationItemDateInput
              id="period_from"
              name="period_from"
              value={formik.values.period_from}
              onChange={formik.handleChange}
              placeholder="С"
              isInvalid={formik.values.period_from === ''}
            />
            <InformationItemDateInput
              id="period_to"
              name="period_to"
              value={formik.values.period_to}
              onChange={formik.handleChange}
              placeholder="По"
              isInvalid={formik.values.period_to === ''}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem title="Объекты строительства">
          {currentContract?.short_description_construction_object ?? currentLimitFenceCard?.construction_objects}
        </InformationItem>
        <InformationItem title="Исполнитель">
          {currentContract?.executor ?? currentLimitFenceCard?.performer_full_name}
        </InformationItem>
        <InformationItem title="Ответственный">
          {currentContract ? "" : currentLimitFenceCard?.responsible_full_name}
        </InformationItem>
      </InformationBlock>
      <CompensationActModal isInsideCreate={true} isOpen={isOpen} onClose={onClose}/>
    </>
  );
}
