import { Flex } from "@chakra-ui/react";
import { Subtitle } from "../lib/subtitle/Subtitle";
import { Title } from "../lib/title/Title";
import MainLogo from "../../images/svg/main-logo/main-logo.svg";
import SuccessLogo from "../../images/png/success.png";

interface AuthTitleProps {
  title: string;
  subtitle?: string;
  isSuccess?: boolean;
}

export function AuthTitle(props: Readonly<AuthTitleProps>) {
  const { title, subtitle, isSuccess } = props;

  return (
    <Flex direction="column" gap={6}>
      <Flex justify="center">
        <img src={isSuccess ? SuccessLogo : MainLogo} alt="Main logo" />
      </Flex>
      <Flex direction="column" gap={3}>
        <Title textAlign="center">{title}</Title>
        {subtitle && <Subtitle text={subtitle} />}
      </Flex>
    </Flex>
  );
}
