import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink} from "@chakra-ui/react";

interface IStockCardPageBreadcrumbs {
  currentPageText: string
}

export function StockCardPageBreadcrumbs({currentPageText}: IStockCardPageBreadcrumbs) {
  return (
    <Breadcrumb
      fontSize={14}
      separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
    >
      <BreadcrumbItem>
        <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink href="/purchase">Закупки</BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink href="/purchase/stock-cards">Лимитно-заборные карты</BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem isCurrentPage color="#8089A2">
        <BreadcrumbLink>
          {currentPageText}
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )
}
