import {Flex, Radio, RadioGroup, Stack, Text} from "@chakra-ui/react";
import {BlockTitle} from "../lib/title/Title";
import {DocumentsFields} from "../CreateActBasedOnEstimateComponents/DocumentsFields";
import {useState} from "react";

export function SingleEstimateActPrintData() {
  const [radioValue, setRadioValue] = useState<string>("2");
  return(
    <Flex direction="column" gap={2}>
      <BlockTitle>Аванасовые документы</BlockTitle>
      <RadioGroup size="lg" colorScheme="teal" defaultValue="2" onChange={setRadioValue}>
        <Stack h="40px" spacing={5} direction="row" align="center">
          <Radio value="1" borderColor="#AAB0C1">
            <Text fontSize="16px">Это первый платеж</Text>
          </Radio>
          <Radio
            alignItems="center"
            alignSelf="center"
            value="2"
            borderColor="#AAB0C1"
          >
            <Text fontSize="16px">Это не первый платеж</Text>
          </Radio>
        </Stack>
      </RadioGroup>
      {radioValue === "2" && <DocumentsFields />}
    </Flex>
  )
}
