import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IPayment,
  IPaymentsResponse,
  ISinglePaymentResponse,
} from "../../api/payments/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { IFile } from "../../api/files/types";

interface IPaymentsInitialState extends ICommonInitialState {
  payments: IPayment[];
  currentPayment: IFile[] | null;
}

const initialState: IPaymentsInitialState = {
  payments: [],
  currentPayment: null,
  isLoading: false,
  error: null,
};

export const fetchPayments = createAsyncThunkInstance<
  IPaymentsResponse,
  undefined
>("payments/fetchPaymentsByContract", api.payments.getPayments);

export const fetchSinglePayment = createAsyncThunkInstance<
  ISinglePaymentResponse,
  string
>("payments/fetchSinglePayment", api.payments.getSinglePayment);

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    resetCurrentPayment(state) {
      state.currentPayment = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPayments.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payments = action.payload.data;
      })

      .addCase(fetchSinglePayment.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSinglePayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentPayment = action.payload.data;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const paymentsReducer = paymentsSlice.reducer;
export const { resetCurrentPayment } = paymentsSlice.actions;
