import { SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { MainTable } from "./MainTable";
import {useAppSelector} from "../../../store";
import {useEffect, useState} from "react";
import { formatAmount } from "../../../helpers/formatAmount";

export function MainTableBlock() {
  const { currentDetails } = useAppSelector((state) => state.estimates);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debounceSearchValue, setDebounceSearchValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | string | number | undefined>();


  useEffect(() => {
    const totalSum = currentDetails.reduce((sum: number, detail) => {
      let quantity = 0;
      
      if ((Number(detail.quantityinthedocument) === 0) && (Number(detail.Thenumberofstops) !== 0)) {
        quantity = Number(detail.Thenumberofstops)
      } else {
        quantity = Number(detail.quantityinthedocument)
      };
      
      const price = Number(detail.Price) || 0;
      return sum + quantity * price;
    }, 0);

    const fixedTotalSum = totalSum.toFixed(2)
    setTotal(Number(fixedTotalSum));
  }, [currentDetails]);

  useEffect(() => {
    if (debounceTimer) clearTimeout(debounceTimer);

    const newTimer = setTimeout(() => {
      if(debounceSearchValue !== searchValue){setDebounceSearchValue(searchValue)};
    }, 300);

    setDebounceTimer(newTimer);
    return () => clearTimeout(newTimer);
  }, [searchValue]);

  return (
    <Flex direction="column" gap={2}>
      <Flex w="full" align="center" justify="space-between">
        <InputGroup bg="white" borderColor="#E3E7EF" maxW="320px">
          <InputLeftElement>
            <SearchIcon color="#838A9D" />
          </InputLeftElement>
          <Input placeholder="Поиск" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
        </InputGroup>
        <Text fontWeight={500}>ИТОГО: {formatAmount(total)}</Text>
      </Flex>
      <MainTable searchValue={debounceSearchValue}/>
    </Flex>
  );
}
