import { Input } from "../../lib/input/Input";
import { useFormik } from "formik";

export function ChangeNameModal() {
  const onSubmit = () => {};

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      patronymic: "",
    },

    onSubmit,
  });

  return (
    <>
      <Input
        placeholder="Введите фамилию"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
      />
      <Input
        placeholder="Введите имя"
        name="surname"
        value={formik.values.surname}
        onChange={formik.handleChange}
      />
      <Input
        placeholder="Введите отчество"
        name="patronymic"
        value={formik.values.patronymic}
        onChange={formik.handleChange}
      />
    </>
  );
}
