import { useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { Center, IconButton } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Table } from "../lib/table/Table";
import { BeatLoader } from "../lib/beatLoader/BeatLoader";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { IFile } from "../../api/files/types";
import { fetchSingleFile } from "../../store/slices/files";

const columnHelper = createColumnHelper<IFile>();


const DownloadCell = (info: CellContext<IFile, string>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleButtonClick = () => {
    setIsLoading(true);
    dispatch(fetchSingleFile(info.row.original.guid))
    .then((res) => {
      if (res.payload instanceof Blob && info.row.original.name) {
        const url = window.URL.createObjectURL(
          new Blob([res.payload], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", info.row.original.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .finally(() => {
      setIsLoading(false);
    });

  };

  return (
    <IconButton
      bg="none"
      color="#2AB6A5"
      aria-label="download"
      icon={<DownloadIcon />}
      spinner={<BeatLoader />}
      isLoading={isLoading}
      _disabled={{ bg: "none" }}
      onClick={handleButtonClick}
    />
  );
};

export function DocumentListTable() {

  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );

  const files = currentContract?.files ? currentContract?.files : [];

  const columns = useMemo<Array<ColumnDef<IFile, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Название документа",
      }),
      {
        header: "Тип",
        id: "type",
        cell: "АР",
      },
      {
        header: "Файлы",
        id: "download",
        cell: DownloadCell,
      },
    ],
    []
  );

  return (
    <Table dblClickDisabled={true} data={files} columns={columns} />
  );
}
