import {CellContext} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
import {Link, Text} from "@chakra-ui/react";
import {checkPermission} from "../../helpers/checkPermission";
import {RootState, useAppSelector} from "../../store";

export function ContractCell<T>(info: CellContext<T, string>, id: string, maxWidth?: string) {
  const navigate = useNavigate();
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  return checkPermission(["contract_read", "contract_write"], permissions) ? (
    <Link maxW={maxWidth} color="#2AB6A5" noOfLines={2} onClick={() => navigate(`/my-documents/contracts/${id}`)}>
      {info.renderValue()}
    </Link>
  ) : (
    <Text maxW={maxWidth} noOfLines={2}>
      {info.renderValue()}
    </Text>
  );
}
