import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { RootState, useAppSelector } from "../../store";
import { StatusCell } from "../StatusCell/StatusCell";
import { Link } from "@chakra-ui/react";

export function SingleConstructionRemarkInformationBlock() {
    const { currentRemarkConstruction } = useAppSelector(
        (state: RootState) => state.remarks
    );

    return (
        <InformationBlock>
            <InformationItem title="Организация">{currentRemarkConstruction?.a101_org_name}</InformationItem>
            <InformationItem title="Номер документа">{currentRemarkConstruction?.number}</InformationItem>
            <InformationItem title="Дата">
                {currentRemarkConstruction?.date}
            </InformationItem>
            <InformationItem title="Планируется устранить">{currentRemarkConstruction?.planned_to_eliminate}</InformationItem>
            <InformationItem title="Дата первого “выполнено”">{currentRemarkConstruction?.first_done_date}</InformationItem>
            <InformationItem title="Дата первого “проверено”">
                {currentRemarkConstruction?.first_check_date}
            </InformationItem>
            <InformationItem title="Фактически устранено">
                {currentRemarkConstruction?.effectively_eliminated}
            </InformationItem>
            <InformationItem title="Документ наименование">{currentRemarkConstruction?.document_name}</InformationItem>
            <InformationItem title="Исполнитель">
                {currentRemarkConstruction?.executer}
            </InformationItem>
            <InformationItem title="Договор">
                <Link href={`/my-documents/contracts/${currentRemarkConstruction?.contract_id}`} color="#2AB6A5">{currentRemarkConstruction?.contract_name}</Link>
            </InformationItem>
            <InformationItem title="Статус">
                <StatusCell value={currentRemarkConstruction?.status ?? ""} />
            </InformationItem>
        </InformationBlock>
    );
}
