import { Flex } from "@chakra-ui/react"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { Title } from "../../components/lib/title/Title"
import { PaymentSettlementsTable } from "../../components/PaymentSettlementsTable/PaymentSettlementsTable"

export const PaymentSettlementPage = () => {
    return (
        <MainLayout>
            <Flex direction="column" gap={4} p="6">
                <Title>Взаиморасчеты</Title>
                <PaymentSettlementsTable />
            </Flex>
        </MainLayout>
    )
}   