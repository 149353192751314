import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Grid,
  GridItem,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { SingleInvoiceInformationBlock } from "../../components/SingleInvoiceComponents/SingleInvoiceInformationBlock";
import { Comment } from "../../components/Comment/Comment";
import { RootState, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import {
  approveSingleInvoice, attachFilesToSingleInvoice,
  fetchSingleInvoice,
} from "../../store/slices/invoices";
import { Loader } from "../../components/Loader/Loader";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { IFilesAttachResponse } from "../../api/files/types";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import ComponentWithProps from "../../helpers/ComponentWithProps";

export function SingleInvoicePage({ id, goBack, dispatch, filesModal, setFilesModal }: any) {
  const UpdateInvoiceInformationBlock = ComponentWithProps(SingleInvoiceInformationBlock)
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleInvoice(id));
    }
  }, [dispatch, id]);

  const { currentInvoice } = useAppSelector(
    (state: RootState) => state.invoices
  );
  const [files, setFiles] = useState(currentInvoice?.files ?? []);

  const fileAttach = (files: File[]) => {
    if (currentInvoice) {
      dispatch(attachFilesToSingleInvoice({id: currentInvoice.guid, files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  useEffect(() => {
    if (currentInvoice) {
      setFiles(currentInvoice.files ?? []);
    }
  }, [currentInvoice]);

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p="6">
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/documents-in-progress">
                Документы в работе
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/documents-in-progress/invoices">
                Счёт-фактуры
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>
                {`Счёт-фактура № ${currentInvoice?.invoice_number} от ${currentInvoice?.invoice_date}`}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/documents-in-progress/invoices")} />
              <BlockTitle>
                {`Счёт-фактура № ${currentInvoice?.invoice_number} от ${currentInvoice?.invoice_date}`}
              </BlockTitle>
            </Flex>
            <Flex gap={3}>
              <OutlineButton
                leftIcon={<Icon as={Document} fill={"#E8363D"} />}
                onClick={() => setFilesModal(true)}
              >
                Файлы
              </OutlineButton>
              <PrimaryButton
                onClick={() => {
                  if (id) {
                    dispatch(approveSingleInvoice(id));
                  }
                }}
              >
                Согласовать
              </PrimaryButton>
            </Flex>
          </Flex>

          <Grid gap={4} gridTemplateColumns="1fr 504px">
            <GridItem>
              <UpdateInvoiceInformationBlock />
            </GridItem>
            <GridItem>
              <Comment currentComment={currentInvoice?.comment} />
            </GridItem>
          </Grid>
        </Flex>
      </Flex>
      {isSpinnerRunning && <Loader isLoading={isSpinnerRunning} />}
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        fileAttach={fileAttach}
      />
    </MainLayout>
  );
}
