import { Outlet } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { LinkTabs } from "../../components/lib/tabs/Tabs";
import { documentsInProgressPageTabs } from "../../shared/mock/documentsInProgress";

export function DocumentsInProgressPage() {
  return (
    <MainLayout>
      <Flex direction="column" gap={4} p="6">
        <Title>Документы в работе</Title>
        <LinkTabs tabs={documentsInProgressPageTabs} />
        <Outlet />
      </Flex>
    </MainLayout>
  );
}
