import { useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Table } from "../../lib/table/Table";

type TBuildingObject = {
  id: number;
  title: string;
  address: string;
};

const buildingObject: TBuildingObject = {
  id: 1,
  title: "Многоквартирный жилой дом №5.2 с благоустройством территории",
  address: "Москва, п. Сосенское, вблизи д. Николо-Хованское",
};

const columnHelper = createColumnHelper<TBuildingObject>();

export function BuildingObjectsTable() {
  const columns = useMemo<Array<ColumnDef<TBuildingObject, string>>>(
    () => [
      columnHelper.accessor("title", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("address", {
        header: "Номер",
      }),
    ],
    []
  );

  return <Table data={Array(6).fill(buildingObject)} columns={columns} />;
}
