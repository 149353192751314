import {  useMemo } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { Center, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import { ISavingsCompensationAct } from "../../api/savingsCompensationActs/types";
import { useAppSelector } from "../../store";
import { fetchSavingsCompensationActs } from "../../store/slices/savingsCompensationActs";
import { formatAmount } from "../../helpers/formatAmount";
import { DownloadFilesModal } from "../Modal/ModalRight/DownloadFilesModal";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";

const columnHelper = createColumnHelper<ISavingsCompensationAct>();

const ProjectCell = (info: CellContext<ISavingsCompensationAct, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

export function CompensationActTable({ filesModal, setFilesModal }: any) {
  const { savingsCompensationActs } = useAppSelector(
    (state) => state.savingsCompensationActsReducer
  );
  const FilesCell = () => (
    <Center>
      <ExternalLinkIcon onClick={() => setFilesModal(true)} color="#2AB6A5" cursor="pointer" />
    </Center>
  );

  const columns = useMemo<Array<ColumnDef<ISavingsCompensationAct, string>>>(
    () => [
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues })
      }),
      columnHelper.accessor("number", {
        header: "Номер",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("project_name", {
        header: "Проект",
        cell: ProjectCell,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма компенсации",
        cell: (info) => formatAmount(info.getValue())
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id)),
        enableColumnFilter: true,
      }),
      columnHelper.accessor("start_date", {
        header: (info) => FilterHeader(info.column, info.table, "Дата начала"),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { start_date } }, id, filterValues) =>
          filterFunction({ value: start_date, filterValues })
      }),
      columnHelper.accessor("end_date", {
        header: (info) => FilterHeader(info.column, info.table, "Дата окончания"),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { end_date } }, id, filterValues) =>
          filterFunction({ value: end_date, filterValues })
      }),
      {
        id: "files",
        header: "Файлы",
        cell: FilesCell,
      },
    ],
    []
  );
  return (
    <>
      <Table minHeight="300px" data={savingsCompensationActs} columns={columns} fetchFunction={fetchSavingsCompensationActs} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
      />
    </>
  ) 
}
