import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { EstimatesEndpoints } from "../endpoints";
import { IEstimatesResponse, ISingleEstimateResponse } from "./types";

export const getEstimates = (id: number): AxiosPromise<IEstimatesResponse> =>
  axiosInstance.get(EstimatesEndpoints.ESTIMATES + `/${id}` + EstimatesEndpoints.ESTIMATES_AND_SPRECIFICATIONS);

export const getSingleEstimate = (
  id: number
): AxiosPromise<ISingleEstimateResponse> =>
  axiosInstance.get(EstimatesEndpoints.ESTIMATE_DETAILS + `/${id}`);
