import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";

interface ISingleFileInitialState extends ICommonInitialState {
  currentFile: Blob | null;
}

const initialState: ISingleFileInitialState = {
  currentFile: null,
  isLoading: false,
  error: null,
};


export const fetchSingleFile = createAsyncThunkInstance<
  Blob,
  string
>(
  "files/fetchSingleFile",
  api.files.getFile
);

const filesSlice = createSlice({
  name: "savingsCompensationActs",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleFile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentFile = action.payload;
      })
  },
});

export const filesReducer =
  filesSlice.reducer;
