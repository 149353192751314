import { Accordion, AccordionButton, AccordionItem, Box } from "@chakra-ui/react";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import {
  InformationItem,
} from "../InformationBlock/InformationItem";
import { RootState, useAppSelector } from "../../store";
import { ChevronDownIcon } from "@chakra-ui/icons";

export function SingleCompensationActInformationBlock() {

  const { currentSavingsCompensationAct } = useAppSelector(
    (state: RootState) => state.savingsCompensationActsReducer
  );
  return (
    <InformationBlock>
      <InformationItem title="Договор">
      <Accordion color="#AAB0C1" border="1px solid #EBEEF5" borderRadius="8px">
        <AccordionItem>
          <h2>
            <AccordionButton cursor="default">
              <Box as="span" flex='1' textAlign='left'>
                {currentSavingsCompensationAct?.contract_name}
              </Box>
              <ChevronDownIcon />
            </AccordionButton>
          </h2>
        </AccordionItem>
        </Accordion>
      </InformationItem>
      <InformationItem title="Номер">{currentSavingsCompensationAct?.number}</InformationItem>
      <InformationItem title="Организация">
        {currentSavingsCompensationAct?.a101_org_name}
      </InformationItem>
      <InformationItem title="Контрагент">{currentSavingsCompensationAct?.organization_name}</InformationItem>
      <InformationItem title="Проект">{currentSavingsCompensationAct?.project_name}</InformationItem>
      <InformationItem title="ССОМ">
        {currentSavingsCompensationAct?.ssom}
      </InformationItem>
      <InformationItem title="Период">
        {`${currentSavingsCompensationAct?.start_date} — ${currentSavingsCompensationAct?.end_date}`}
      </InformationItem>
      <InformationItem title="Сумма компенсации">{currentSavingsCompensationAct?.amount}</InformationItem>
      <InformationItem title="Объекты строительства">
        {currentSavingsCompensationAct?.description_construction_object}
      </InformationItem>
      <InformationItem title="Исполнитель">
        {currentSavingsCompensationAct?.performer_full_name}
      </InformationItem>
      <InformationItem title="Ответственный">
        {currentSavingsCompensationAct?.responsible_full_name}
      </InformationItem>
    </InformationBlock>
  );
}
