import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IAccessAct,
  IAccessActsResponse,
  ISingleAccessAct,
  ISingleAccessActResponse,
} from "../../api/accessActs/types";
import { ICommonResponse } from "../../api/commonResponseType";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { IFilesAttachResponse } from "../../api/files/types";
import { AxiosError } from "axios";
import { attachFiles } from "../../api/files";
import { AccessActsEndpoints, Files } from "../../api/endpoints";

export interface AccessActsState extends ICommonInitialState {
  accessActs: IAccessAct[];
  currentAccessAct: ISingleAccessAct | null;
}

const initialState: AccessActsState = {
  accessActs: [],
  currentAccessAct: null,
  isLoading: false,
  error: null,
};

export const fetchAccessActs = createAsyncThunkInstance<
  IAccessActsResponse,
  undefined
>("accessActs/fetchAccessActs", api.accessActs.getAccessActs);

export const fetchSingleAccessAct = createAsyncThunkInstance<
  ISingleAccessActResponse,
  number
>("accessActs/fetchSingleAccessAct", api.accessActs.getSingleAccessAct);

export const editAccessAct = createAsyncThunk<
  ICommonResponse,
  { params: FormData; id: number },
  { rejectValue: string }
>(
  "accessActs/editAccessAct",
  async ({ params, id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.accessActs.editAccessAct(params, id);
      dispatch(fetchSingleAccessAct(id));

      return data;
    } catch (error: unknown) {
      console.error(error);

      throw rejectWithValue("Не удалось получить данные");
    }
  }
);

export const attachFilesToSingleAccessAct = createAsyncThunk<
  IFilesAttachResponse,
  { id: number; files: File[] },
  { rejectValue: AxiosError }
>(
  "accessActs/attachFilesToSingleAccessAct",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(AccessActsEndpoints.SINGLE_ACCESS_ACT + `/${id}` + Files.ATTACH_FILES, files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

const accessActsSlice = createSlice({
  name: "accessActs",
  initialState,
  reducers: {
    resetCurrentAccessAct(state) {
      state.currentAccessAct = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAccessActs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accessActs = action.payload.data;
      })

      .addCase(fetchSingleAccessAct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAccessAct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentAccessAct = action.payload.data;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const accessActsReducer = accessActsSlice.reducer;
export const { resetCurrentAccessAct } = accessActsSlice.actions;
