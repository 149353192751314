export const actMenuList: { title: string, onClick?: () => void }[] = [
  {
    title: "КС-2",
  },
  {
    title: "Счёт",
  },
  {
    title: "Счёт-фактуры"
  },
  {
    title: "Отгрузочные документы",
  },
  {
    title: "Накопительная ведомость",
  },
  {
    title: "КС-3"
  }
];
