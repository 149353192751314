import {useEffect, useMemo, useState} from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import { Input } from "../lib/input/Input";
import { Table } from "../lib/table/Table";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { fetchContracts } from "../../store/slices/contracts";
import { IContract } from "../../api/contracts/types";
import { formatAmount } from "../../helpers/formatAmount";

const columnHelper = createColumnHelper<IContract>();

interface TitleProps {
    onChange: (inp: string) => void;
}

const TitleHeader = ({onChange}: TitleProps) => {
  return (
    <Flex align="center" justify="space-between" w="full" gap="16px">
      <span>Название</span>
      <Input small search placeholder="Поиск" onChange={(e) => onChange(e.target.value)}/>
    </Flex>
  );
};

const TitleCell = ({ row: { original } }: CellContext<IContract, string>) => {
  return (
    <Flex align="center" justify="space-between" gap="16px">
      <span>{original.name}</span>
      <Flex minW={12} gap={3} color="#2AB6A5">
        {original.has_working_documents && <AttachmentIcon cursor="pointer" />}
        {original.edo && <Icon as={EditPen} cursor="pointer" fill="#2AB6A5" />}
      </Flex>
    </Flex>
  );
};


const ProjectCell = ({ row: { original } }: CellContext<IContract, string>) => (
  <Box>
    <Text>{original.short_description_construction_object}</Text>
    <Text fontSize="14px" lineHeight="22px" color="#8089A2">
      {original.executor}
    </Text>
  </Box>
);

export function ContractsTable() {
  const { contracts } = useAppSelector((state: RootState) => state.contracts);
  const [search, setSearch] = useState('');
  const onChange= (str: string) =>  {
      setSearch(str)
  }

  const columns = useMemo<Array<ColumnDef<IContract, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: () => TitleHeader({onChange}),
        cell: TitleCell,
      }),
      columnHelper.accessor("contract_date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      {
        id: "project",
        header: "Проект и исполнитель",
        enableHiding: false,
        cell: ProjectCell,
      },
      columnHelper.accessor("cost", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
    ],
    []
  );

  return <Table
    data={search.trim() === '' ? contracts : contracts.filter(it => (it.name.toLowerCase()).includes(search.toLowerCase()))}
    columns={columns} 
    defaultSorting={[{id: "contract_date", desc: true}]}
    fetchFunction={fetchContracts}
    />;
}
