import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { Files, PaymentsEndpoints } from "../endpoints";
import { IPaymentsResponse, ISinglePaymentResponse } from "./types";

export const getPayments = 
(): AxiosPromise<IPaymentsResponse> =>
  axiosInstance.get(PaymentsEndpoints.PAYMENTS);

export const getSinglePayment = (
  id: string
): AxiosPromise<ISinglePaymentResponse> =>
  axiosInstance.get(PaymentsEndpoints.SINGLE_PAYMENT + `/${id}` + Files.FILE);
