import {Modal, ModalProps} from "../Modal";
import styles from ".././modal.module.scss";
import {ModalHeader} from "./ModalHeader/ModalHeader";
import {ModalFooter} from "./ModalFooter/ModalFooter";
import {PrimaryButton} from "../../lib/button/Button";
import {useEffect, useState} from "react";
import {DropFileInputInSingleComponents} from "../../lib/input/drop-file-input/DropFileInputInSingleComponents";
import {Text} from "@chakra-ui/react";
import {FilesTable} from "../../FilesTable/FilesTable";
import {IFile} from "../../../api/files/types";
import {useLocation} from "react-router-dom";
import { checkUploadFiles } from "../../../helpers/checkFiles";

type DownloadFilesModalProps = Omit<ModalProps, "children"> & {
  title: string;
  value?: File[];
  deleteValue?: File;
  receivedFiles?: IFile[];
  fileAttach?: (files: File[]) => void;
  setFilesOnCreate?: (files: File[]) => void;
};

export function DownloadFilesModal(props: DownloadFilesModalProps) {
  const {active, title, onClose, value = [], receivedFiles, fileAttach, setFilesOnCreate} = props;
  const [downloadFileList, setDownloadFileList] = useState<File[]>(value);
  const [deleteFile, setDeleteFile] = useState<File>();
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);


  const {pathname} = useLocation();
  const isCreate = pathname.includes("create");

  const handleCloseModal = () => {
    onClose();
  };

  const sendFiles = () => {
    fileAttach && fileAttach(downloadFileList);
    setDownloadFileList([]);
    onClose();
  }

  const handleFileInputChange = (files: File[]) => {
    setDownloadFileList(files);
    setFilesOnCreate && setFilesOnCreate(files);
  };

  function handleDelete(file: File) {
    if (file.lastModified) {
      setDeleteFile(file);
    }
  }

  function clearDeleteFiles() {
    setDeleteFile(undefined)
  }

  useEffect(() => {
    const validFiles = checkUploadFiles(downloadFileList);
    if (validFiles) {
      setIsValidFile(false);
    } else {
      setIsValidFile(true);
    }

    if (isCreate || downloadFileList.length === 0 || validFiles) {
      setIsDisabledButton(true);
    } else {
      setIsDisabledButton(false);
    }
  }, [downloadFileList, isCreate]);

  return (
    <Modal active={active} onClose={handleCloseModal}>
      <div
        className={styles.modal_content}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.container}>
          <ModalHeader title={title} onClose={handleCloseModal} />
          <form className={styles.form}>
            <div className={styles.modal_info}>
              <DropFileInputInSingleComponents
                clearDeleteFiles={clearDeleteFiles}
                deleteFile={deleteFile}
                text="Перетащите сюда файл(ы)"
                name="attachments"
                onChange={handleFileInputChange}
              />
              <FilesTable
                receivedFiles={receivedFiles}
                handleDelete={handleDelete}
                filesList={downloadFileList}
              />
              {!isValidFile && (
                <Text color="red">Файлы не могут быть отправлены</Text>
              )}
            </div>
            <ModalFooter>
              <PrimaryButton
                onClick={sendFiles}
                isDisabled={isDisabledButton}
              >
                Отправить
              </PrimaryButton>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Modal>
  );
}
