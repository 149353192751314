import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import {routes, RouteType} from "./routes";
import { useEffect } from "react";
import { GlobalModalWrapper, GlobalModal } from 'react-global-modal'
import 'react-global-modal/dist/style.css'
import { ToastContainer } from "react-toastify";
import {RootState, useAppSelector} from "./store";
import {checkPermission} from "./helpers/checkPermission";

let globalModalRef: any = null

function App() {
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const findRedirectRoute = (children: RouteType[] | undefined): string => {
    const foundChild = children && children.find(route => route.permission && checkPermission(route.permission, permissions))
    return foundChild ? foundChild.path : ""
  }

  useEffect(() => {
    GlobalModal.setUpModal(globalModalRef)
  }, [])

  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/login" replace />} />
        {routes.map((route) => {
          const { path, Component, children, index, to = "", differentPermissions } = route;

          return (
            <Route key={path} path={path} element={<Component />}>
              {index && <Route index element={<Navigate to={differentPermissions ? findRedirectRoute(children) : to} />} />}
              {!!children?.length &&
                children.map((child) => (
                  <Route
                    key={child.path}
                    path={child.path}
                    element={<child.Component />}
                  />
                ))}
            </Route>
          );
        })}
      </Route>
    </Routes>
    <GlobalModalWrapper ref={(el) => (globalModalRef = el)} />
    <ToastContainer></ToastContainer>
    </>
  );
}

export default App;
