import { useMemo } from "react";
import { useAppSelector } from "../../../store";
import {
  createColumnHelper,
  ColumnDef,
  CellContext,
} from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";
import { Table } from "../../lib/table/Table";
import { IDetail } from "../../../api/estimates/types";
import { formatAmount } from "../../../helpers/formatAmount";

const columnHelper = createColumnHelper<IDetail>();

const WorkTypeCell = (info: CellContext<IDetail, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

export function BuildingObjectsTable() {
  const { currentDetails } = useAppSelector((state) => state.estimates);
  const filteredDetails = currentDetails.filter(detail => Number(detail.quantityinthedocument) !== 0);

  const columns = useMemo<Array<ColumnDef<IDetail, string>>>(
    () => [
      columnHelper.accessor("ConstructionObjectName", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("TypeofWorkName", {
        header: "Вид работы",
        cell: WorkTypeCell,
      }),
      columnHelper.accessor("quantityinthedocument", {
        header: "Количество",
      }),
      columnHelper.accessor("QuantityInTheOriginalEstimate", {
        header: "Количество предварительное",
      }),
      columnHelper.accessor("Price", {
        header: "Цена",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("Total", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
    ],
    []
  );

  return <Table data={filteredDetails} columns={columns} />;
}
