import { CellContext, ColumnDef, Row, createColumnHelper } from "@tanstack/react-table";
import { IContract } from "../../api/contracts/types";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { Input } from "../lib/input/Input";
import { AttachmentIcon } from "@chakra-ui/icons";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useMemo, useState } from "react";
import { fetchContracts, fetchSingleContract } from "../../store/slices/contracts";
import dayjs from "dayjs";
import { formatAmount } from "../../helpers/formatAmount";
import { Table } from "../lib/table/Table";
import { TitleProps } from "../../shared/types/tableProps";
import { ICompensationActsContractsTableProps } from "../../api/savingsCompensationActs/types";

const columnHelper = createColumnHelper<IContract>();

const TitleHeader = ({onChange}: TitleProps) => {
  return (
    <Flex align="center" justify="space-between" w="full" gap="16px">
      <span>Название</span>
      <Input small search placeholder="Поиск" onChange={(e) => onChange(e.target.value)}/>
    </Flex>
  );
};

const TitleCell = ({ row: { original } }: CellContext<IContract, string>) => {
  return (
    <Flex align="center" justify="space-between" gap="16px">
      <span>{original.name}</span>
      <Flex minW={12} gap={3} color="#2AB6A5">
        {original.has_working_documents && <AttachmentIcon cursor="pointer" />}
        {original.edo && <Icon as={EditPen} cursor="pointer" fill="#2AB6A5" />}
      </Flex>
    </Flex>
  );
};

const ProjectCell = ({ row: { original } }: CellContext<IContract, string>) => (
  <Box>
    <Text>{original.short_description_construction_object}</Text>
    <Text fontSize="14px" lineHeight="22px" color="#8089A2">
      {original.executor}
    </Text>
  </Box>
);

export function CreatCompensationActTable(props: Readonly<ICompensationActsContractsTableProps>) {
  const {onChangeLoading, changeStatusButtonDisabled} = props;
  const dispatch = useAppDispatch();
  const { contracts } = useAppSelector((state: RootState) => state.contracts);
  const [search, setSearch] = useState('');
  const onChange = (text: string) =>  {
    setSearch(text)
  }

  const filteredContracts = contracts.filter((el) => {
    return el.status === "Зарегистрирован"
  })

  const handleEstimatesTableRowClick = (row: Row<IContract>) => {
    row.toggleSelected();
    if (row.getIsSelected()) {
      changeStatusButtonDisabled(true)
    } else {
      onChangeLoading(true);
      changeStatusButtonDisabled(false)
      dispatch(fetchSingleContract(row.original.id))
        .catch(e => {
          console.error('Error: ', e);
        })
        .finally(() => {
          onChangeLoading(false);
        });
    }
  };

  useEffect(() => {
    dispatch(fetchContracts());
  }, [dispatch]);

  const columns = useMemo<Array<ColumnDef<IContract, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: () => TitleHeader({onChange}),
        cell: TitleCell,
      }),
      columnHelper.accessor("contract_date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      {
        id: "project",
        header: "Проект и исполнитель",
        enableHiding: false,
        cell: ProjectCell,
      },
      columnHelper.accessor("cost", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
    ],
    []
  );

  return <Table
    data={search.trim() === '' ? filteredContracts : filteredContracts.filter(el => (el.name.toLowerCase()).includes(search.toLowerCase()))}
    columns={columns} defaultSorting={[{id: "contract_date", desc: true}]}
    rowClick={handleEstimatesTableRowClick}
    dblClickDisabled={true}
    />;
  
}
