import { useLocation } from "react-router-dom";
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { useEffect } from "react";
import { TutorialModal } from "../../components/Modal/ModalCenter/TutorialModal";
import { RootState, useAppSelector } from "../../store";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { RequireAuth } from "../../hoc/RequireAuth";
import { Title } from "../../components/lib/title/Title";
import { MainPageComponents } from "../../components/MainPageComponents/MainPageComponents";

export const MainPage = () => {

    const location = useLocation();

    const { isOnboardingСompleted } = useAppSelector(
        (state: RootState) => state.onboarding
    );

    const { screens } = useAppSelector((state: RootState) => state.onboarding);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (location.state === '/login' && !isOnboardingСompleted) {
            onOpen();
        }
    }, [location.state, isOnboardingСompleted, onOpen]);

    return (
        <RequireAuth>
            <MainLayout>
                <Flex overflowY="scroll" maxH="100%" direction="column" gap={4} p="6">
                    <Title>Главная</Title>
                    <MainPageComponents />
                </Flex>
                <TutorialModal
                    active={isOpen}
                    onClose={onClose}
                    screens={screens.map((screen) => screen.content)}
                />
            </MainLayout>
        </RequireAuth>
    )
}