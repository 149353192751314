import { useEffect, useState } from "react";
import {
  useSearchParams,
} from "react-router-dom";
import { RootState, useAppSelector } from "../../store";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Grid,
  GridItem,
  Link,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  fetchSingleContract,
  fetchSingleContractAccessActs,
  fetchSingleContractWorkCompletedActs,
} from "../../store/slices/contracts";
import { PrimaryButton } from "../../components/lib/button/Button";
import { BlockTitle, Title } from "../../components/lib/title/Title";
import { ChakraTabs } from "../../components/lib/tabs/Tabs";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { SingleContractInformationBlock } from "../../components/SingleContractComponents/SingleContractInformationBlock";
import { RemarksTable } from "../../components/RemarkTable/RemarksTable";
import { EstimateBlock } from "../../components/EstimateBlock/EstimateBlock";
import { ActsBlock } from "../../components/ActsBlock/ActsBlock";
import { ReconciliationActBlock } from "../../components/ReconciliationAct/ReconciliationActBlock";
import { PaymentBlock } from "../../components/Payment/PaymentBlock";
import { IndemnityLetterBlock } from "../../components/IndemnityLetter/IndemnityLetterBlock";
import { WorkingDocumentationModal } from "../../components/WorkingDocumentationModal/WorkingDocumentationModal";
import { boundDocumentsTabs } from "../../shared/mock/singleDocumentPageData";
import { OnboardingLink } from "../../components/OnboardingLink/OnboardingLink";
import ComponentWithProps from "../../helpers/ComponentWithProps";

enum ParamKeys {
  Remarks = "remarks",
  RelatedDocuments = "related-docs",
}

export function SingleContractPage({ id, dispatch, navigate, goBack }: any) {
  const UpdatedEstimateBlock = ComponentWithProps(EstimateBlock)
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [isCollapsedList, setIsCollapsedList] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const relatedDocsTabIndex = Number(
    searchParams.get(ParamKeys.RelatedDocuments)
  );

  const setDefaultTabParam =
    (key: ParamKeys) =>
    (index: number): void => {
      index === 0
        ? searchParams.delete(key)
        : searchParams.set(key, String(index));

      setSearchParams(searchParams);
    };

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleContract(Number(id)));
      dispatch(fetchSingleContractWorkCompletedActs(Number(id)));
      dispatch(fetchSingleContractAccessActs(Number(id)));
    }
  }, [dispatch, id]);

  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href={currentContract?.is_archived ? `/archive` : '/my-documents/contracts'}>
              {currentContract?.is_archived ? "Архив договоров" : "Договоры"}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink
                href={currentContract?.is_archived ? `/archive/${currentContract?.id}` : `/my-documents/contracts/${currentContract?.id}`}
              >
                {currentContract?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()} />
              <BlockTitle>{currentContract?.name}</BlockTitle>
            </Flex>
            <PrimaryButton leftIcon={<ExternalLinkIcon />} onClick={onOpen}>
              Рабочая документация
            </PrimaryButton>
            <WorkingDocumentationModal onClose={onClose} isOpen={isOpen} />
          </Flex>

          <SingleContractInformationBlock />
        </Flex>

        <Flex direction="column" gap={4}>
          <Title mb={2}>Замечания при строительстве</Title>
          <RemarksTable isCollapsedList={isCollapsedList} />
          <Flex justify="space-between" color="#2AB6A5">
            <Link onClick={() => setIsCollapsedList(!isCollapsedList)}>
              {!isCollapsedList ? "Развернуть список" : "Свернуть"}
            </Link>
            <Link onClick={() => navigate("/remarks/construction")}>
              Перейти в раздел <ChevronRightIcon />
            </Link>
          </Flex>
        </Flex>

        <Flex direction="column" gap={4}>
          <Title>Связанные документы</Title>
          <Tabs
            defaultIndex={relatedDocsTabIndex}
            onChange={setDefaultTabParam(ParamKeys.RelatedDocuments)}
          >
            <ChakraTabs tabs={boundDocumentsTabs} />
            <TabPanels>
              <TabPanel px={0} pb={0} gap={40}>
                <Grid templateColumns="repeat(2, 1fr)" gap={4} h="456px">
                  <GridItem maxH="full" overflow="hidden">
                    <UpdatedEstimateBlock />
                  </GridItem>
                  <GridItem maxH="full" overflow="hidden">
                    <ActsBlock />
                  </GridItem>
                </Grid>
                <Box marginTop={10}>
                  <OnboardingLink />
                </Box>
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <ReconciliationActBlock />
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <PaymentBlock />
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <IndemnityLetterBlock />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </MainLayout>
  );
}
