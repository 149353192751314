import { AuthTitle } from "../components/AuthTitle/AuthTitle";
import { Modal, ModalProps } from "../components/Modal/Modal";
import styles from "../components/Modal/modal.module.scss"
import { PrimaryButton } from "../components/lib/button/Button";

type changeModalProps = Omit<ModalProps, "children"> & {
    children: React.JSX.Element;
    title: string;
  };

export function ChangeModal(props: changeModalProps) {
    const { active, onClose, title, children } = props;
 
    return (
    <Modal active={active} onClose={onClose}>
      <form
        className={styles.center_modal_container}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <AuthTitle title={title} />
        <div className={styles.input_block}>
          {children}
        </div>
        <PrimaryButton type="submit">Сохранить</PrimaryButton>
      </form>
    </Modal>
  );
}
