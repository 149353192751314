import { ITab } from "../../components/lib/tabs/Tabs";

export const myDocumentsPageTabs: ITab[] = [
  {
    id: 1,
    href: "contracts",
    label: "Договоры",
    permission: ["contract_read", "contract_write"]
  },
  { id: 2, href: "access-acts", label: "Акты допуска", permission: "access_act_write" },
];

export const singleAccessActPageTabs: ITab[] = [
  { id: 1, label: "Объекты строительства" },
  { id: 2, label: "Файлы", notice: 1 },
];
