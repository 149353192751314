import {
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { IRemarksConstruction } from "../../api/remarks/types";
import { useMemo } from "react";
import { Table } from "../lib/table/Table";
import dayjs from "dayjs";
import { FilterHeader } from "../Filter/FilterHeader";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";
import { RootState, useAppSelector } from "../../store";
import { fetchRemarksConstructionList } from "../../store/slices/remarks";
import { ContractCell } from "../ContractCell/ContractCell";

const columnHelper = createColumnHelper<IRemarksConstruction>();

export function RemarksConstructionTable() {
  const { remarksConstructionList } = useAppSelector(
    (state: RootState) => state.remarks
  );

  const columns = useMemo<Array<ColumnDef<IRemarksConstruction, string>>>(
    () => [
      columnHelper.accessor("number", {
        header: "Номер",
      }),
      columnHelper.accessor("date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue(), "#AAE2DB"),
        enableColumnFilter: true,
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id)),
      }),
      columnHelper.accessor("project_name", {
        header: "Проект",
      }),
    ],
    []
  );
  return <Table fetchFunction={fetchRemarksConstructionList} isGuid={true} minHeight="300px" data={remarksConstructionList} columns={columns} />;
}
