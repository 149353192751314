import { Flex, Link } from "@chakra-ui/react";
import { BlockTitle } from "../../lib/title/Title";
import { BuildingObjectsTable } from "./BuildingObjectsTable";

export function BuildingObjectsBlock() {
  return (
    <Flex direction="column" gap={4}>
      <BlockTitle>Объекты строительства</BlockTitle>
      <BuildingObjectsTable />
      <Link color="#2AB6A5">Развернуть список</Link>
    </Flex>
  );
}
