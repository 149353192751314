import { Input, Link } from "@chakra-ui/react";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { InformationItemForm } from "../InformationBlock/InformationItemForm";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppSelector } from "../../store";
import { useEffect } from "react";

export function SingleOrderLetterInformationBlock() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentSupplier } = useAppSelector((state: RootState) => state.directionLetters);
  const { currentLimitFenceCard } = useAppSelector((state: RootState) => state.limitFenceCards);
  const { profileData } = useAppSelector((state: RootState) => state.profile);
  
  useEffect(() => {
    if (!currentLimitFenceCard) {
      navigate("/purchase/stock-cards")
    }
  }, [currentLimitFenceCard])

  
  return (
    <InformationBlock>
      <InformationItem title="Статус"></InformationItem>
      <InformationItem title="Статус оплаты"></InformationItem>
      <InformationItem title="Номер"></InformationItem>
      <InformationItem title="Подрядчик" isEdited>
        {profileData.profile?.organization.org_name}
      </InformationItem>
      <InformationItem title="Поставщик">{currentSupplier?.name}</InformationItem>
      <InformationItem title="ИНН поставщика">{currentSupplier?.inn}</InformationItem>
      <InformationItem title="КПП поставщика">{currentSupplier?.kpp}</InformationItem>
      <InformationItem title="Договор">
        <Link color="#2AB6A5">
          {currentLimitFenceCard?.contract_name}
        </Link>
      </InformationItem>
      <InformationItem title="Организация">
      {currentLimitFenceCard?.organization_name}
      </InformationItem>  
      <InformationItem title="Распорядительное письмо">
        <InformationItemForm firstValue=" " secondLabel="от" secondValue="Выберите дату" />
      </InformationItem>
      <InformationItem title="Сумма">
        <Input
          h="36px"
          rounded="md"
          borderColor="#E3E7EF"
          placeholder="Введите значение"
          position="absolute"
          top="50%"
          transform="translate(0, -50%)"
        />
      </InformationItem>
      <InformationItem title="НДС">
        <Input
          h="36px"
          rounded="md"
          borderColor="#E3E7EF"
          placeholder="Введите значение"
          position="absolute"
          top="50%"
          transform="translate(0, -50%)"
        />
      </InformationItem>
      <InformationItem title="Лимитно-заборная карта">
        {currentLimitFenceCard?.number}
      </InformationItem>
    </InformationBlock>
  );
}
