import { Text } from "@chakra-ui/react";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { StatusCell } from "../StatusCell/StatusCell";
import { CheckIcon } from "@chakra-ui/icons";
import { RootState, useAppSelector } from "../../store";

export function SingleRemarkTransmissionInformationBlock() {
  const { currentRemarkTransmission } = useAppSelector(
    (state: RootState) => state.remarks
  );
  return (
    <InformationBlock>
      <InformationItem title="Номер">{currentRemarkTransmission?.number}</InformationItem>
      <InformationItem title="Повторное">

        {currentRemarkTransmission?.repeated ? (
          <CheckIcon color="#8089A2" />
        ) : (
          <></>
        )}
      </InformationItem>
      <InformationItem title="Дата">{currentRemarkTransmission?.date}</InformationItem>
      <InformationItem title="Классификатор замечаний">
        {currentRemarkTransmission?.comment_classifier}
      </InformationItem>
      <InformationItem title="Статус">
        <StatusCell value={currentRemarkTransmission?.status ?? ""} />
      </InformationItem>
      <InformationItem title="Объект строительства">{currentRemarkTransmission?.construction_object}</InformationItem>
      <InformationItem title="Объект недвижимости">{currentRemarkTransmission?.real_estate_object}</InformationItem>
      <InformationItem title="Вид помещения">{currentRemarkTransmission?.type_of_premises}</InformationItem>
      <InformationItem title="Клиент">
        {currentRemarkTransmission?.client}
      </InformationItem>
      <InformationItem title="Телефон клиента">
        <Text color="#2AB6A5">{currentRemarkTransmission?.client_phone}</Text>
      </InformationItem>
      <InformationItem title="Номер помещения БТИ">
        {currentRemarkTransmission?.room_number_bti}
      </InformationItem>
      <InformationItem title="Номер помещения">
        {currentRemarkTransmission?.room_number}
      </InformationItem>
      <InformationItem title="Корпус">{currentRemarkTransmission?.housing}</InformationItem>
      <InformationItem title="Подъезд">{currentRemarkTransmission?.entrance}</InformationItem>
      <InformationItem title="Этаж">{currentRemarkTransmission?.floor}</InformationItem>
      <InformationItem title="Номер на площадке ">
        {currentRemarkTransmission?.number_on_the_site}
      </InformationItem>
      <InformationItem title="Подрядчик виновник">
        {currentRemarkTransmission?.contractor_is_the_culprit}
      </InformationItem>
      <InformationItem title="Подрядчик исполнитель">
        {currentRemarkTransmission?.contractor_performer}
      </InformationItem>
      <InformationItem title="Субподрядчик">
        {currentRemarkTransmission?.subcontractor}
      </InformationItem>
    </InformationBlock>
  );
}
