import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import { Box, Text } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { useNavigate } from "react-router-dom";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { remarksTableList } from "../../shared/mock/RemarksTableList";

export interface IMark {
  id: number;
  number: string;
  date: string;
  status: string;
  buildObject: {
    name: string;
    manager: string;
  };
}

const columnHelper = createColumnHelper<IMark>();

export function RemarksTable({
  isCollapsedList,
}: {
  isCollapsedList: boolean;
}) {
  const navigate = useNavigate();
  const [resultData, setResultData] = useState<IMark[]>([]);
  const buildObjectCell = useCallback(
    ({ row: { original } }: CellContext<IMark, string>) => (
      <>
        <Text noOfLines={1}>{original.buildObject.name}</Text>
        <Box lineHeight="21px" fontSize={14} color="#8089a2" noOfLines={1}>
          {original.buildObject.manager}
        </Box>
      </>
    ),
    []
  );

  function navigateToPage(id: number) {
    navigate(`/remarks/construction/${id}`);
  }

  useEffect(() => {
    if (isCollapsedList) {
      setResultData(remarksTableList);
    } else {
      setResultData(remarksTableList.slice(0, 6));
    }
  }, [isCollapsedList]);

  const columns = useMemo<Array<ColumnDef<IMark, string>>>(
    () => [
      columnHelper.accessor("number", {
        header: "Номер",
      }),
      columnHelper.accessor("date", {
        header: "Дата",
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue(), "#469DDD40"),
      }),
      {
        id: "buildObject",
        header: "Объект строительства",
        enableHiding: false,
        cell: buildObjectCell,
      },
    ],
    [buildObjectCell]
  );

  return (
    <Table
      data={resultData}
      dblClickHandler={navigateToPage}
      columns={columns}
    />
  );
}
