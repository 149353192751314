import { Flex } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { RequirementsAccountTable } from "../../components/RequirementsAccountTable/RequirementsAccountTable";

export function RequirementsAccountPage() {
  return (
    <MainLayout>
      <Flex direction="column" gap={4} p="6">
        <Title>Учёт требований</Title>
        <RequirementsAccountTable />
      </Flex>
    </MainLayout>
  );
}
