import { useMemo } from "react";
import {
  createColumnHelper,
  ColumnDef,
} from "@tanstack/react-table";
import { Table } from "../../lib/table/Table";
import { useAppSelector } from "../../../store";
import { fetchInvoices } from "../../../store/slices/invoices";
import { IInvoice } from "../../../api/invoices/types";
import { formatAmount } from "../../../helpers/formatAmount";
import { ContractCell } from "../../ContractCell/ContractCell";

const columnHelper = createColumnHelper<IInvoice>();

export function InvoiceTable() {
  const { invoices } = useAppSelector((state) => state.invoices);

  const columns = useMemo<Array<ColumnDef<IInvoice, string>>>(
    () => [
      columnHelper.accessor("invoice_date", {
        header: "Дата",
        enableSorting: true,
      }),
      columnHelper.accessor("invoice_number", {
        header: "Номер",
      }),
      columnHelper.accessor("organization_name", {
        header: "Организация",
      }),
      columnHelper.accessor("included_document_number", {
        header: "Номер вх.",
      }),
      columnHelper.accessor("included_document_date", {
        header: "Дата вх.",
        enableSorting: true,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id), "268px"),
      }),
    ],
    []
  );

  return <Table isGuid={true} data={invoices} columns={columns} fetchFunction={fetchInvoices} />;
}
