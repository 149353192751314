import { Center } from "@chakra-ui/react";

interface NoticeProps {
  number: number;
  overdueNotice?: number;
}

export function Notice(props: Readonly<NoticeProps>) {
  const {number, overdueNotice} = props;
  return (
    <Center
      w={overdueNotice ? 9 : 5}
      h={5}
      rounded="full"
      color="#ffffff"
      bg="#e8363d"
      fontSize="12px"
      lineHeight="12px"
      marginLeft={overdueNotice ? '-10px' : 0}
    >
      {overdueNotice ? (
        <span>{`${number} (${overdueNotice})`}</span>
      ) : (
        <span>{number}</span>
      )}
    </Center>
  );
}
