import React, {useEffect, useState} from "react";
import {Flex, InputGroup} from "@chakra-ui/react";
import {Input} from "../lib/input/Input";
import {InformationItemDateInput} from "../InformationBlock/InformationItemDateInput";
import {InformationBlock} from "../InformationBlock/InformationBlock";

interface IInput {
  name: string,
  date: string,
}

export function DocumentsFields() {
  const [inputs, setInputs] = useState<IInput[]>([{name: "", date: ""}]);

  const handleChange = (value: string, fieldName: keyof IInput, index: number) => {
    let onChangeValue = [...inputs];
    onChangeValue[index][fieldName] = value;
    setInputs(onChangeValue)
  }

  useEffect(() => {
    if (inputs[inputs.length - 1].date && inputs[inputs.length - 1].name) {
      const duplicateArray = [...inputs, {name: "", date: ""}]
      setInputs(duplicateArray)
    }
  }, [inputs])

  return <InformationBlock>
    <Flex direction="column" gap={4}>
    {inputs.map((input, index) => (
      <InputGroup key={index} gap={4}>
        <Input
          id={`document_field_name_${index}`}
          name={`document_field_name_${index}`}
          value={inputs[index].name}
          onChange={(event) => handleChange(event.target.value, "name", index)}
          placeholder="Название документа"
          width="100%"
        />
        <InformationItemDateInput
          id={`document_field_date_${index}`}
          name={`document_field_date_${index}`}
          w="240px"
          placeholder="От"
          onChange={(event) => handleChange(event.target.value, "date", index)}
        />
      </InputGroup>
    ))}
    </Flex>
  </InformationBlock>
}