
import { Flex } from "@chakra-ui/react"
import { ReactComponent as Message } from "../../images/svg/main-icons/message.svg";
import { ReactComponent as Eye } from "../../images/svg/main-icons/eye.svg";
import { ReactComponent as Requirement } from "../../images/svg/main-icons/requirements.svg";
import { ReactComponent as Document } from "../../images/svg/main-icons/document.svg";
import { BoxItem } from "./BoxItem";
import { InformationBlock } from "./InformationBlock";

export const MainPageComponents = () => {
    return (
        <Flex gap="12px">
            <BoxItem
                headerTitle="Визирование"
                headerIcon={<Eye />}
            >
                <InformationBlock title="Всего" count="5" />
                <InformationBlock title="Просроченные" count="134" />
            </BoxItem>
            <BoxItem
                headerTitle="Замечания"
                headerIcon={<Message />}
            >
                <InformationBlock title="При строительстве" count="19" />
                <InformationBlock title="При передаче ОН" count="9" />
            </BoxItem>
            <BoxItem
                headerTitle="Требования"
                headerIcon={<Requirement />}
            >
                <InformationBlock title="Всего" count="4" />
            </BoxItem>
            <BoxItem
                headerTitle="Подписать"
                headerIcon={<Document />}
            >
                <InformationBlock title="Всего" count="13" />
            </BoxItem>
        </Flex>
    )
}