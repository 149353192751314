type TWorkingDocumentationInfo = {
  id: number;
  title: string;
  content: string;
};

export const workingDocumentationInfo: TWorkingDocumentationInfo[] = [
  {
    id: 1,
    title: "Коммерческое название объекта",
    content: "Прошино",
  },
  {
    id: 2,
    title: "Участок",
    content: "СУ №112 ООО",
  },
  {
    id: 3,
    title: "Название объекта",
    content: "С200-002079",
  },
  {
    id: 4,
    title: "ID объекта",
    content: "ППТ_1-4_Уч_1_СОШ",
  },
];
