import { Flex } from "@chakra-ui/react";
import { Title } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { ArchiveTable } from "../../components/ArchiveTable/ArchiveTable";

export function ArchivePage() {
  return (
    <MainLayout>
      <Flex direction="column" gap={4} p="6">
        <Title>Архив договоров</Title>
        <ArchiveTable />
      </Flex>
    </MainLayout>
  );
}
