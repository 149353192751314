import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { OnboardingEndpoints } from "../endpoints";
import { IUserScreensResponse, OnboardingCompleteResponse } from "./types";
import { downloadElement } from "../../helpers/downloadElement";

export const getAllUserScreens = (): AxiosPromise<IUserScreensResponse> => {
  return axiosInstance.get(OnboardingEndpoints.USER_SCREENS);
};

export const getSingleUserScreen = (
  id: number
): AxiosPromise<IUserScreensResponse> => {
  return axiosInstance.get(OnboardingEndpoints.SINGLE_SCREEN + `/${id}`);
};

export const setOnbordingCompeleted = (): AxiosPromise<OnboardingCompleteResponse> => {
  return axiosInstance.post(OnboardingEndpoints.ONBOARDING_COMPLETE);
};

export const downloadOnboardingPDF = () =>
  downloadElement(OnboardingEndpoints.DOWNLOAD_ALL_SCREENS, "onboarding");

export const downloadSingleScreenOnboardingPDF = (id: number) =>
  downloadElement(
    `${OnboardingEndpoints.DOWNLOAD_SINGLE_SCREEN}/${id}`,
    "onboarding-single-screen"
  );
