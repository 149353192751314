import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex,
  Grid,
  GridItem, Icon, IconProps,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { useEffect, useState } from "react";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import {
  SingleConstructionRemarkInformationBlock
} from "../../components/SingleConstructionRemarkComponents/SingleConstructionRemarkInformationBlock";
import {
  SingleConstructionRemarkInfo
} from "../../components/SingleConstructionRemarkComponents/SingleConstructionRemarkInfo";
import {AppBlock} from "../../components/AppBlock/AppBlock";
import { RootState, useAppSelector } from "../../store";
import { applyRemarkCounstruction, fetchSingleRemarkConstruction } from "../../store/slices/remarks";

export function SingleConstructionRemarkPage({ goBack, id, dispatch, filesModal, setFilesModal }: any) {

  useEffect(() => {
    id && dispatch(fetchSingleRemarkConstruction(id));
  }, [dispatch, id]);

  const { currentRemarkConstruction } = useAppSelector(
    (state: RootState) => state.remarks
  );

  const CheckDoubleIcon = (props: IconProps) => (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path>
    </Icon>
  );

  const handleApplyRemarkCounstruction = () => {
    id && dispatch(applyRemarkCounstruction(id))
  }

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%"/>}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/reports">Замечания</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>
                {currentRemarkConstruction?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()} />
              <BlockTitle>{currentRemarkConstruction?.name}</BlockTitle>
            </Flex>

            <ButtonGroup spacing={3}>
              <OutlineButton
                leftIcon={<Icon as={Document} fill={"#E8363D"} />}
                gap={2}
                onClick={() => setFilesModal(true)}
                >
                Файлы
              </OutlineButton>
              <PrimaryButton
                onClick={handleApplyRemarkCounstruction}
                leftIcon={<CheckDoubleIcon />}
              >
                Выполнено
              </PrimaryButton>
            </ButtonGroup>
          </Flex>
          <Grid templateColumns="1fr 504px" gap={4}>
            <GridItem>
              <SingleConstructionRemarkInformationBlock />
            </GridItem>
            <GridItem>
              <AppBlock />
            </GridItem>
          </Grid>

          <SingleConstructionRemarkInfo />
        </Flex>
      </Flex>

      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={currentRemarkConstruction?.files}
      />
    </MainLayout>
  );
}
