import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {RootState, useAppSelector} from "../../store";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink, ButtonGroup,
  CircularProgress,
  Flex, Icon,
  Text, useDisclosure
} from "@chakra-ui/react";
import {
  resetCurrentAccessAct,
} from "../../store/slices/accessActs";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {BlockTitle} from "../../components/lib/title/Title";
import {MainLayout} from "../../components/MainLayout/MainLayout";
import {CreateActInformationBlock} from "../../components/CreateActBasedOnEstimateComponents/CreateActInformationBlock";
import {FormikProps, useFormik} from "formik";
import {ICreateWorkCompletedActRequest} from "../../api/workCompletedActs/types";
import {addWorkCompletedAct, fetchSingleWorkCompletedActs} from "../../store/slices/workCompletedActs";
import * as Yup from "yup";
import {OutlineButton, PrimaryButton} from "../../components/lib/button/Button";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import styles from "../CreateActBasedOnEstimatePage/create-act.module.scss";
import {Loader} from "../../components/Loader/Loader";
import {CloseFormModal} from "../../components/CloseFormModal/CloseFormModal";
import {
  BuildingObjectsBlock
} from "../../components/CreateActBasedOnEstimateComponents/BuildingObjectsBlock/BuildingObjectsBlock";
import {SingleEstimateActPrintData} from "../../components/SingleEstimateActPrintData/SingleEstimateActPrintData";
import {checkPermission} from "../../helpers/checkPermission";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";

interface HeaderButtonGroupProps {
  onSubmit: (status: string) => void;
  isLoading: boolean;
  closeLoading: (arg: boolean) => void;
  fieldsAreSet: () => boolean;
  onOpen: () => void;
  setFilesModal: (arg:boolean) => void
}

const progressBlocks = [
  {
    title: "РС",
    percent: 58
  },
  {
    title: "ТН",
    percent: 41
  },
  {
    title: "Бэк-офис",
    percent: 16
  }
];

export function SingleWorkCompletedAct({ goBack, dispatch, filesModal, setFilesModal }: any) {
  const {currentContract} = useAppSelector((state) => state.contracts)
  const { currentEstimate, currentDetails } = useAppSelector((state) => state.estimates);
  const detailsWithQuantity = currentDetails.filter(detail => Number(detail.quantityinthedocument) !== 0);
  const {act_id} = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isRunSpinner, setIsRunSpinner] = useState(false);

  const onSubmit = (status: string) => {
    if (currentContract && currentEstimate) {
      setIsRunSpinner(true);
      dispatch(
        addWorkCompletedAct({
          ...formik.values,
          status,
        })
      )
        .then(() => { setIsRunSpinner(false); goBack(); })
        .catch((e: Error) => {
          console.error('Error: ', e);
          setIsRunSpinner(false);
        });
    }
  };

  const formik: FormikProps<ICreateWorkCompletedActRequest> =
    useFormik<ICreateWorkCompletedActRequest>({
      initialValues: {
        invoice_number: "459618721612",
        invoice_date: "2023-03-09",
        act_number: "РЕ00-000009",
        act_date: "2023-02-14",
        sf_number: "РЕ230000103",
        sf_date: "2023-03-09",
        manager_name: "Читаишвили Зураб Тамазович",
        manager_name_power_of_attorney: "459618721612",
        chief_accountant: "Салтыкова Елена Фёдоровна",
        chief_accountant_power_of_attorney: "",
        date_from: "2023-10-12",
        date_to: "2023-12-12",
        status: "",
        contract_id: currentContract?.id ?? 0,
        estimate_id: currentEstimate?.id ?? 0,
        details: detailsWithQuantity.map((detail) => ({
          ConstructionObjectID: detail.ConstructionObjectID,
          TypeofWork: detail.TypeofWork,
          quantity: Number(detail.quantityinthedocument),
          Price: Number(detail.Price),
          Total: Number(detail.Total),
        })),
      },
      validationSchema: Yup.object({
        invoice_number: Yup.string().required(),
        invoice_date: Yup.string().required(),
        act_number: Yup.string().required(),
        act_date: Yup.string().required(),
        sf_number: Yup.string().required(),
        sf_date: Yup.string().required(),
        manager_name: Yup.string().required(),
        manager_name_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
            Yup.string().required() : Yup.number().positive().nullable()
        ),
        chief_accountant: Yup.string().required(),
        chief_accountant_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
            Yup.string().required() : Yup.number().positive().nullable()
        ),
        date_from: Yup.string().required(),
        date_to: Yup.string().required(),
        status: Yup.string().required(),
        contract_id: Yup.number().required(),
        estimate_id: Yup.number().required(),
        details: Yup.array().required(),
      }),
      onSubmit: () => dispatch(addWorkCompletedAct(formik.values)),
      enableReinitialize: true,
      validateOnBlur: true,
      validateOnMount: true,
    });

  const fieldsAreSet = () => {
    return formik.values.invoice_number.trim() !== '' && formik.values.invoice_date !== '' &&
      formik.values.act_number.trim() !== '' && formik.values.act_date !== '' &&
      formik.values.sf_number.trim() !== '' && formik.values.sf_date !== '' &&
      formik.values.manager_name.trim() !== '' && formik.values.chief_accountant.trim() !== '' &&
      formik.values.date_from !== '' && formik.values.date_to !== '';
  }

  useEffect(() => {
    act_id && dispatch(fetchSingleWorkCompletedActs(Number(act_id)));

    return () => {
      dispatch(resetCurrentAccessAct());
    };
  }, [dispatch, act_id]);

  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap="24px" p={6}>
        <Flex direction="column" gap="16px">
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%"/>}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/my-documents/contracts">Договоры</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`/my-documents/contracts/${currentContract?.id}`}>{currentContract?.name}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink href="#">
                Акт на основе сметы
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()}/>
              <BlockTitle>
                Акт на основе сметы
              </BlockTitle>
            </Flex>
            <HeaderButtonGroup
              onSubmit={onSubmit}
              isLoading={isRunSpinner}
              closeLoading={setIsRunSpinner}
              fieldsAreSet={fieldsAreSet}
              onOpen={onOpen}
              setFilesModal={setFilesModal}
            />
          </Flex>
          <WorkCompletedActProgressBlock />
        </Flex>

        <CreateActInformationBlock formik={formik} organization="ООО «МПС КОМПЛЕКСНЫЕ РЕШЕНИЯ»" managerPoAInputDisabled={false} />
        <BuildingObjectsBlock />
        <SingleEstimateActPrintData />
      </Flex>
      <CloseFormModal isOpen={isOpen} onClose={onClose} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
      />
    </MainLayout>
  );
}

function WorkCompletedActProgressBlock() {
  return(
    <Flex justify="space-between" align="center" gap="12px">
      {progressBlocks.map(progressBlock => (
        <Box key={progressBlock.title} padding="10px 24px" bg="#FFFFFF" borderRadius={8} border="1px solid #E3E7EF" width="100%">
          <Flex gap="24px" align="center">
            <CircularProgress value={progressBlock.percent} size="44px" color="#2AB6A5" trackColor="#E3E7EF" />
            <Text>{progressBlock.title} — {progressBlock.percent}%</Text>
          </Flex>
        </Box>
        ))}
    </Flex>
  )
}

const HeaderButtonGroup = (props: HeaderButtonGroupProps) => {
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  return (
    <ButtonGroup spacing={3}>
      <OutlineButton onClick={props.onOpen}>Отмена</OutlineButton>
      <OutlineButton
        leftIcon={<Icon as={Document} fill={"#E8363D"} />}
        gap={2}
        onClick={() => props.setFilesModal(true)}
      >
        Файлы
      </OutlineButton>
      {
        !props.isLoading && checkPermission("contract_write", permissions) && (
          <PrimaryButton className={props.fieldsAreSet() ? '': styles.disabled } onClick={() => props.onSubmit("Рабочий документ")}>
            Записать
          </PrimaryButton>
        )
      }

      {props.isLoading &&
        <Loader isLoading={props.isLoading} />
      }

    </ButtonGroup>
  );
};
