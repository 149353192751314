import { useState } from "react";
import {
  Center,
  Fade,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputGroupProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { ChatIcon, CheckIcon, CopyIcon, EditIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../lib/button/Button";
import { BlockTitle, Title } from "../lib/title/Title";
import { ExecutorMessageModal } from "../Modal/ModalRight/ExecutorMessageModal";
import MainLogo from "../../images/svg/main-logo/main-logo.svg";
import SuccessLogo from "../../images/png/success.png";
import { ChakraModalProps } from "../../shared/types/modalProps";

interface InformationItemProps {
  title?: string;
  children?: string | JSX.Element | JSX.Element[];
  isHeading?: boolean;
  isEdited?: boolean;
  isExecutor?: boolean;
  alertItem?: boolean;
  isAccordion?: boolean;
  isDisabled?: boolean;
}

export function InformationItem(
  props: Readonly<InformationItemProps>
): JSX.Element {
  const { title = "", isEdited = false, isExecutor = false, alertItem = false, isAccordion = false, isDisabled = false } = props;
  const [copied, setCopied] = useState(false);

  const {
    isOpen: isCopyOpen,
    onOpen: onCopyOpen,
    onClose: onCopyClose,
  } = useDisclosure();

  const {
    isOpen: isExecutorMessageOpen,
    onOpen: onExecutorMessageOpen,
    onClose: onExecutorMessageClose,
  } = useDisclosure();

  const { isOpen: isEditOpen, onClose: onEditClose } = useDisclosure();

  const handleCopyClick = (): void => {
    navigator.clipboard.writeText(`${props.children}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Tr
      display="flex"
      alignItems="center"
      gap="16px"
      onMouseEnter={onCopyOpen}
      onMouseLeave={onCopyClose}
    >
      {!alertItem && (
        <Td
          p="14px 0"
          color="#5C657E"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          borderBottom="none"
          w="42%"
          alignSelf="self-start"
        >
          {props.isHeading ? (
            <Text
              pt="32px"
              pb="16px"
              lineHeight="16px"
              fontWeight={500}
              color="#343B4C"
            >
              {title}
            </Text>
          ) : (
            title
          )}
        </Td>
      )}
      <Td
        p={alertItem ? "0" : isAccordion ? "14px 0" : "14px 18px 14px 0"}
        position="relative"
        borderBottom="none"
        flex={1}
      >
        {props.children}
        <Center
          position="absolute"
          right={0}
          top="50%"
          transform="translate(0, -50%)"
          cursor="pointer"
        >
          {!isExecutor && !isEdited && typeof props.children === "string" && (
            <Fade in={isCopyOpen}>
              <IconButton
                aria-label="copy"
                color="#8089A2"
                bg="none"
                _hover={{ bg: "none" }}
                _disabled={{ bg: "none" }}
                icon={<CopyIcon />}
                spinner={<CheckIcon />}
                isLoading={copied}
                onClick={handleCopyClick}
              />
            </Fade>
          )}
          {isExecutor && (
            <ChatIcon
              color={isDisabled ? "#E3E7EF" : "#2AB6A5"}
              onClick={() => {
                !isDisabled && onExecutorMessageOpen();
              }}
            />
          )}
          {isEdited && <EditIcon color="#8089A2" />}
        </Center>

        {isExecutor && (
          <ExecutorMessageModal
            title="Написать исполнителю"
            active={isExecutorMessageOpen}
            onClose={onExecutorMessageClose}
          />
        )}
        {isEdited && <EditModal isOpen={isEditOpen} onClose={onEditClose} />}
      </Td>
    </Tr>
  );
}

function EditModal(props: Readonly<ChakraModalProps>): JSX.Element {
  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();

  const handleEditModalClose = () => {
    props.onClose();
    onSuccessOpen();
  };

  return (
    <>
      <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
          <ModalHeader p={0}>
            <Flex direction="column" align="center" gap="24px" pb="8px">
              <Image src={MainLogo} boxSize="64px" alt="Main Logo" />
              <Title textAlign="center">Электронный адрес подрядчика</Title>
            </Flex>
          </ModalHeader>
          <ModalBody p={0}>
            <Input placeholder="Введите электронный адрес подрядчика" />
          </ModalBody>
          <ModalFooter p={0}>
            <PrimaryButton w="full" onClick={handleEditModalClose}>
              Сохранить
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessModal isOpen={isSuccessOpen} onClose={onSuccessClose} />
    </>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>): JSX.Element {
  return (
    <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            <BlockTitle textAlign="center">
              Электронный адрес подрядчика успешно изменён
            </BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={props.onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function InformationItemInputGroup(
  props: Readonly<InputGroupProps>
): JSX.Element {
  return (
    <InputGroup
      gap={3}
      alignItems="center"
      position="absolute"
      top="50%"
      transform="translate(0, -50%)"
    >
      {props.children}
    </InputGroup>
  );
}
