import { useMemo } from "react";
import {
  ColumnDef,
  Row,
  createColumnHelper,
} from "@tanstack/react-table";
import { Flex } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { ReactComponent as EditFile } from "../../images/svg/documents/edit-file.svg";
import { formatAmount } from "../../helpers/formatAmount";
import { IEstimate } from "../../api/estimates/types";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";

type TIndemnityLetterItem = {
  id: number;
  title: string;
  sum: string;
  status: string;
};

const indemnityLetterTableDataItem: TIndemnityLetterItem = {
  id: 1,
  title: "Гарантийное письмо СУ00-000005 от 11.01.2021 11:20:34",
  sum: "110457150.93",
  status: "Рабочая",
};

const columnHelper = createColumnHelper<TIndemnityLetterItem>();

const IdCell = () => {
  return (
    <Flex justify="center" cursor="pointer">
      <EditFile />
    </Flex>
  );
};

const handleEstimatesTableRowClick = (row: Row<IEstimate>) => {
  row.toggleSelected();
}

export function IndemnityLetterTable() {
  const columns = useMemo<Array<ColumnDef<TIndemnityLetterItem, string>>>(
    () => [
      columnHelper.accessor("title", {
        header: "Название",
      }),
      {
        id: "id",
        cell: IdCell,
        meta:{
          cellClassName: 'indemnityLetter-idCell'
        },
        enableHiding: false,
      },
      columnHelper.accessor("sum", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue())
      }),

      columnHelper.accessor("status", {
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue()),
      }),
    ],
    []
  );

  return (
    <Table 
      rowClick={handleEstimatesTableRowClick}
      dblClickDisabled={true}
      data={Array(5).fill(indemnityLetterTableDataItem)}
      columns={columns}
    />
  );
}
