import { useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Comment } from "../../components/Comment/Comment";
import { ChakraModalProps } from "../../shared/types/modalProps";
import SuccessLogo from "../../images/png/success.png";
import ErrorLogo from "../../images/svg/error/error.svg";
import { CreateCompensationActComponent } from "../../components/CreateCompensationActComponent/CreateCompensationActComponent";
import {
  addSingleSavingsCompensationAct,
  attachFilesToSingleSavingsCompensationAct
} from "../../store/slices/savingsCompensationActs";
import { RootState, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { Loader } from "../../components/Loader/Loader";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import {CloseAlertModal} from "../../components/CloseAlertModal/CloseAlertModal";
import {
  IAddCompensationActResponse
} from "../../api/savingsCompensationActs/types";

export function CreateCompensationActPage({ filesModal, setFilesModal, dispatch, goBack }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [disabledInput, setDisabledInput] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [statusComment, setStatusComment] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [closeAlertModalOpen, setCloseAlertModalOpen] = useState<boolean>(false);

  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const [dataState, setDataState] = useState({
    contract_id: Number(currentContract?.id),
    period_from: "",
    period_to: "",
    amount: 0,
    comment: "",
  });

  function onChangeData(
    period_from_value: string,
    period_to_value: string,
    amount_value: number
  ) {
    setDataState((dataState) => ({
      ...dataState,
      period_from: period_from_value,
      period_to: period_to_value,
      amount: amount_value,
    }));
  }

  function changeButtonInput(value: boolean) {
    setDisabledInput(value);
  }

  useEffect(() => {
    setDisabled(disabledInput);
  }, [disabledInput]);

  function handleChangeComment(comment_value: string) {
    setDataState((dataState) => ({
      ...dataState,
      comment: comment_value,
    }));
  }

  const setCommentsOnRes = (status: number) => {
    if (status === 500) {
      setStatusComment("Акт не записан");
      setStatus(false);
    } else if (status === 422) {
      setStatusComment("Неверные данные");
      setStatus(false);
    } else {
      setStatus(true);
      setStatusComment("Акт успешно записан");
    }
  }

  const fileAttach = (id: number) => {
    dispatch(attachFilesToSingleSavingsCompensationAct({id: id, files: files}))
      .then((res: { payload: { response: { status: number; }; }; }) => res.payload?.response?.status && setCommentsOnRes(res.payload?.response?.status))
      .catch((e: Error) => {
        console.error('Error: ', e);
      })
      .finally(() => {
        onOpen();
        setIsSpinnerRunning(false);
      });
  }

  function handleSubmitForm() {
    setIsSpinnerRunning(true);
    dispatch(addSingleSavingsCompensationAct(dataState))
      .then((res: { payload: IAddCompensationActResponse; }) => {
        if (files.length) {
          fileAttach((res.payload as IAddCompensationActResponse)?.data.id);
        } else {
          res.payload?.response?.status && setCommentsOnRes(res.payload?.response?.status)
        }
      })
      .catch((error: Error) => {
        console.error("Error: ", error);
        setStatusComment("Акт не записан");
        setStatus(false);
      })
      .finally(() => {
        if (files.length === 0) {
          onOpen();
          setIsSpinnerRunning(false);
        }
      });
  }

  const onBackBtnClick = () => {
    if (files.length) {
      setCloseAlertModalOpen(true);
    } else {
      goBack("/purchase/compensation-act");
    }
  }

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase">Закупки</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase/compensation-act">
                Акт об экономии/компенсации
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>
                Создать акт об экономии/компенсации
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={onBackBtnClick} />
              <BlockTitle>Создать акт об экономии/компенсации</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <OutlineButton
                leftIcon={<DownloadIcon />}
                gap={2}
                onClick={() => setFilesModal(true)}
              >
                Вложенные файлы
              </OutlineButton>
              <PrimaryButton
                isDisabled={disabled}
                onClick={() => {
                  handleSubmitForm();
                }}
              >
                Записать
              </PrimaryButton>
            </ButtonGroup>
          </Flex>
          <Grid templateColumns="1fr 504px" gap={4}>
            <GridItem>
              <CreateCompensationActComponent
                changeButtonInput={changeButtonInput}
                onChangeData={onChangeData}
              />
            </GridItem>
            <GridItem>
              <Comment
                isEdited
                handleChangeComment={handleChangeComment}
              />
            </GridItem>
          </Grid>
        </Flex>
      </Flex>
      <SuccessModal
        isOpen={isOpen}
        onClose={onClose}
        statusComment={statusComment}
        status={status}
      />
      {isSpinnerRunning && <Loader isLoading={isSpinnerRunning} />}
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        setFilesOnCreate={setFiles}
      />
      <CloseAlertModal isOpen={closeAlertModalOpen} onClose={() => setCloseAlertModalOpen(false)} closeDrawer={() => goBack("/purchase/compensation-act")} />
    </MainLayout>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>) {
  const { isOpen, onClose, statusComment, status } = props;
  const navigate = useNavigate();

  const goBack = () => {
    if (status) {
      navigate('/purchase/compensation-act');
    }
  };
  
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            {status ? (
              <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            ) : (
              <Image src={ErrorLogo} boxSize="100px" alt="Error Logo" />
            )}
            <BlockTitle>{statusComment}</BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton 
          w="full" 
          onClick={() => {
            onClose();
            goBack();
          }}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
