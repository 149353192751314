import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  Button,
  Center,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import classNames from "classnames";
import { logoutUser } from "../../store/slices/auth";
import { ReactComponent as Bell } from "../../images/svg/header/bell.svg";
import { ReactComponent as Person } from "../../images/svg/header/person.svg";
import { ReactComponent as PersonDD } from "../../images/svg/header/person-dd.svg";
import { ReactComponent as Logout } from "../../images/svg/header/logout.svg";
import { ReactComponent as Safety } from "../../images/svg/header/safety.svg";
import { ReactComponent as Support } from "../../images/svg/header/support.svg";
import { ReactComponent as Question } from "../../images/svg/question/question.svg";
import { Notice } from "../lib/notice/Notice";
import { SecurityServiceModal } from "../Modal/ModalRight/SecurityServiceModal";
import { TechSupportModal } from "../Modal/ModalRight/TechSupportModal";
import { FAQModal } from "../Modal/ModalRight/FAQModal";
import { NotificationItem } from "../NotificationItem/NotificationItem";
import { notificationData } from "../../shared/mock/notificationData";
import styles from "./header.module.scss";
import {CloseAlertModal} from "../CloseAlertModal/CloseAlertModal";

export function Header() {
  const { isSidebarClosed } = useAppSelector(
    (state: RootState) => state.profile.profileData
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [securityModal, setSecurityModal] = useState<boolean>(false);
  const [supportModal, setSupportModal] = useState<boolean>(false);
  const [successSupportSend, setSuccessSupportSend] = useState<boolean>(false);
  const [successSecuritySend, setSuccessSecuritySend] = useState<boolean>(false);
  const [questionsModal, setQuestionsModal] = useState<boolean>(false);
  const [closeAlertModalOpen, setCloseAlertModalOpen] = useState<boolean>(false);
  const [confirmationLoss, setConfirmationLoss] = useState<boolean>(false);

  const handleLogout = async () => {
    dispatch(logoutUser())
      .unwrap()
      .then(() => navigate("/login", { replace: true }))
      .catch((error) => console.error(error));
  };

  const handleProfile = () => {
    navigate("/profile")
  }

  const headerClass = classNames(styles.header, {
    [styles.wide]: isSidebarClosed,
  });

  const closeTechOrSecurity = () => {
    if (supportModal) {
      setSupportModal(false);
      setSuccessSupportSend(false);
    }
    if (securityModal) {
      setSecurityModal(false);
      setSuccessSecuritySend(false);
    }
  }

  return (
    <>
      <header className={headerClass}>
        <div className={styles.container}>
          <div
            className={styles.question}
            onClick={() => setQuestionsModal(true)}
          >
            <Question />
          </div>

          <div className={styles.content_right}>
            <div className={styles.support_wrapper}>
              <Link
                to="#"
                className={styles.support_item}
                onClick={() => setSecurityModal(true)}
              >
                <div className={styles.logo_wrapper}>
                  <Safety />
                </div>
                <span>Служба Безопасности</span>
              </Link>
              <Link
                to="#"
                className={styles.support_item}
                onClick={() => setSupportModal(true)}
              >
                <div className={styles.logo_wrapper}>
                  <Support />
                </div>
                <span>Техподдержка</span>
              </Link>
            </div>
            <div className={styles.header_bell}>
              <Popover isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                  <Button p={0} bg="none" isActive={isOpen} onClick={onToggle}>
                    <Bell />
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent
                    maxH="calc(100vh - 64px)"
                    mr={1}
                    border="1px solid #E3E7EF"
                    borderRadius="8px"
                    w="480px"
                    boxShadow="lg"
                  >
                    <PopoverArrow />
                    <PopoverHeader
                      fontWeight={500}
                      fontSize="20px"
                      lineHeight="24px"
                      p="16px"
                      borderBottom="1px solid #E3E7EF"
                    >
                      <Text>Уведомления (3)</Text>
                    </PopoverHeader>
                    <PopoverCloseButton p={0} size="lg" top={2} />

                    <PopoverBody
                      overflow="overlay"
                      p={0}
                      className="notification"
                    >
                      {notificationData.map((item) => (
                        <NotificationItem key={item.id} item={item} />
                      ))}
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
              <div>
                <Notice number={2} />
              </div>
            </div>
            <div className={styles.header_person}>
              <Menu>
                <MenuButton as={IconButton} bg="none" rounded="full">
                  <Center>
                    <Person />
                  </Center>
                </MenuButton>
                <MenuList
                  w="200px"
                  border="1px solid #E3E7EF"
                  p={1}
                  boxShadow="0px 8px 24px 0px #1f222a0d"
                >
                  <MenuItem
                    fontWeight={400}
                    justifyContent="flex-start"
                    gap={3}
                    onClick={handleProfile}
                  >
                    <PersonDD />
                    <span>Профиль</span>
                  </MenuItem>
                  <Divider my="2px" borderColor="#E3E7EF" />
                  <MenuItem
                    fontWeight={400}
                    justifyContent="flex-start"
                    gap={3}
                    onClick={handleLogout}
                    color="#E8363D"
                  >
                    <Logout />
                    <span>Выйти</span>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </header>

      <SecurityServiceModal
        title="Написать в службу безопасности"
        active={securityModal}
        onClose={() => {
          successSecuritySend
            ? closeTechOrSecurity()
            : setCloseAlertModalOpen(true);
        }}
        setSuccessSecuritySend={setSuccessSecuritySend}
        confirmationLoss={confirmationLoss}
        setConfirmationLoss={setConfirmationLoss}
      />
      <TechSupportModal
        title="Написать в техподдержку"
        active={supportModal}
        onClose={() => {
          successSupportSend
            ? closeTechOrSecurity()
            : setCloseAlertModalOpen(true);
        }}
        setSuccessSupportSend={setSuccessSupportSend}
        confirmationLoss={confirmationLoss}
        setConfirmationLoss={setConfirmationLoss}
      />

      <FAQModal
        title="Часто задаваемые вопросы"
        active={questionsModal}
        onClose={() => setQuestionsModal(false)}
      />

      <CloseAlertModal
        setConfirmationLoss={setConfirmationLoss}
        isOpen={closeAlertModalOpen}
        onClose={() => {
          setCloseAlertModalOpen(false);
        }}
        closeDrawer={closeTechOrSecurity}
      />
    </>
  );
}


