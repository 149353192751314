import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IContract,
  IContractsResponse,
  ISingleContractResponse,
  ISingleContractWorkCompletedAct,
  ISingleContractWorkCompletedActsResponse,
} from "../../api/contracts/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { IAccessAct, IAccessActsResponse } from "../../api/accessActs/types";

export interface ContractsState extends ICommonInitialState {
  contracts: IContract[];
  archiveContracts: IContract[];
  currentContract: IContract | null;
  currentContractWorkCompletedActs: ISingleContractWorkCompletedAct[];
  currentContractSingleWorkCompletedAct: ISingleContractWorkCompletedAct | null;
  currentContractAccessActs: IAccessAct[];
}

const initialState: ContractsState = {
  contracts: [],
  archiveContracts: [],
  currentContract: null,
  currentContractWorkCompletedActs: [],
  currentContractSingleWorkCompletedAct: null,
  currentContractAccessActs: [],
  isLoading: false,
  error: null,
};

export const fetchContracts = createAsyncThunkInstance<
  IContractsResponse,
  undefined
>("contracts/fetchContracts", api.contracts.getContracts);

export const fetchArchiveContracts = createAsyncThunkInstance<
  IContractsResponse,
  undefined
>("contracts/fetchArchiveContracts", api.contracts.getArchiveContracts);

export const fetchSingleContract = createAsyncThunkInstance<
  ISingleContractResponse,
  number
>("contracts/fetchSingleContract", api.contracts.getSingleContract);

export const fetchSingleContractWorkCompletedActs = createAsyncThunkInstance<
  ISingleContractWorkCompletedActsResponse,
  number
>(
  "contracts/fetchSingleContractWorkCompletedActs",
  api.contracts.getSingleContractWorkCompletedActs
);

export const fetchSingleContractAccessActs = createAsyncThunkInstance<
  IAccessActsResponse,
  number
>("contracts/fetchSingleContractAccessActs", api.contracts.getSingleContractAccessActs)

const contractsSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetCurrentContract(state) {
      state.currentContract = null;
    },
    resetCurrentContractSingleWorkCompletedAct(state) {
      state.currentContractSingleWorkCompletedAct = null;
    },
    setCurrentContractSingleWorkCompletedAct(state, action) {
      state.currentContractSingleWorkCompletedAct = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContracts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contracts = action.payload.data;
        state.contracts.length && (state.contracts[0].status = 'Рабочий');
      })

      .addCase(fetchArchiveContracts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchArchiveContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.archiveContracts = action.payload.data;
      })

      .addCase(fetchSingleContract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentContract = action.payload.data;
      })

      .addCase(fetchSingleContractWorkCompletedActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSingleContractWorkCompletedActs.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.currentContractWorkCompletedActs = action.payload.data;
        }
      )

      .addCase(fetchSingleContractAccessActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSingleContractAccessActs.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.currentContractAccessActs = action.payload.data;
        }
      )

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const contractsReducer = contractsSlice.reducer;
export const { resetCurrentContract, resetCurrentContractSingleWorkCompletedAct, setCurrentContractSingleWorkCompletedAct } = contractsSlice.actions;
