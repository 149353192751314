import { useMemo } from "react";
import { RootState,useAppSelector } from "../../store";
import {
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { fetchAccessActs } from "../../store/slices/accessActs";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import { IAccessAct } from "../../api/accessActs/types";
import { getContractId } from "../../helpers/getContractId";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";

const columnHelper = createColumnHelper<IAccessAct>();

export function AccessActsTable() {
  const { accessActs } = useAppSelector((state: RootState) => state.accessActs);

  const columns = useMemo<Array<ColumnDef<IAccessAct, string>>>(
    () => [
      columnHelper.accessor("act_creation_date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("act_number", {
        header: "Номер",
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
        meta: {
          filterSelect: true
        },
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, getContractId(info.row.original.contract_link)),
        enableColumnFilter: true,
      }),
      columnHelper.accessor("construction_manager", {
        header: "Руководитель строительства",
      }),
      columnHelper.accessor("contractor_exit_date", {
        header: "Дата выхода подрядчика",
        enableSorting: true,
      }),
    ],
    []
  );

  return <Table minHeight="300px" data={accessActs} columns={columns} fetchFunction={fetchAccessActs} />;
}
