import { useState } from "react";
import { PrimaryButton } from "../lib/button/Button";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { useAppDispatch } from "../../store";
import { fetchLzkReport } from "../../store/slices/reports";

export function ExecutionLzkComponent() {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleSendReport = () => {
    setIsOpenModal(true)
    dispatch(fetchLzkReport())
      .finally(() => {
        setIsOpenModal(false)
      })
  }

  return (
    <>
      <PrimaryButton onClick={handleSendReport} w="165px">
        Сформировать
      </PrimaryButton>
      <FullScreenWaitingModal openModal={isOpenModal} />
    </>
  );
}
