import { useMemo } from "react";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { FilterHeader } from "../Filter/FilterHeader";
import { filterFunction } from "../../helpers/filterFunction";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { ReactComponent as Link } from "../../images/svg/link/link.svg";

interface ISigningItem {
    id: number;
    deadline: string;
    document_name: string;
    contract: string;
    type: string;
    project_name: string;
    amount: string;
    diadoc_document: string;
}

const signingItems: ISigningItem[] = [
    {
        id: 1,
        deadline: "15",
        document_name: "Доп. соглашение № 5 от 07.02.2023",
        contract: "Договор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018",
        type: "Виза",
        amount: "139 381 209,64",
        diadoc_document: "Доп. соглашение № 5 от 07.02.2023",
        project_name: "Испанские кварталы 2 очередь"
    },
    {
        id: 1,
        deadline: "11",
        document_name: "Доп. соглашение № 5 от 07.02.2023",
        contract: "Договор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018",
        type: "Доп. запрос",
        amount: "139 381 209,64",
        diadoc_document: "Доп. соглашение № 5 от 07.02.2023",
        project_name: "Испанские кварталы 2 очередь"
    },
    {
        id: 3,
        deadline: "15",
        document_name: "Доп. соглашение № 5 от 07.02.2023",
        contract: "Договор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018",
        type: "Виза",
        amount: "139 381 209,64",
        diadoc_document: "Доп. соглашение № 5 от 07.02.2023",
        project_name: "Испанские кварталы 2 очередь"
    },
    {
        id: 4,
        deadline: "11",
        document_name: "Доп. соглашение № 5 от 07.02.2023",
        contract: "Договор № 0000-000002 от 14.02.2018+Спец 1”, “Счет-договор № 74 от 02.10.2018",
        type: "Доп. запрос",
        amount: "139 381 209,64",
        diadoc_document: "Доп. соглашение № 5 от 07.02.2023",
        project_name: "Испанские кварталы 2 очередь"
    },
];

const columnHelper = createColumnHelper<ISigningItem>();

const DownloadCell = () => (
    <Flex gap="16px">
        <Icon as={EditPen} cursor="pointer" fill="#2AB6A5" />
        <Icon as={Link} cursor="pointer" fill="#2AB6A5" />
    </Flex>
);

const DeadlineHeader = () => (
    <Text textAlign="left">Дней на <br /> подписание</Text>
);

const DiadocHeader = () => (
    <Text textAlign="left">Документ <br /> в Диадок</Text>
);

const TypeCell = (info: CellContext<ISigningItem, string>) => {
    const isVisa = info.getValue() === "Виза";

    return (
        <Text
            fontSize="14px"
            lineHeight="14px"
            py={2}
            px={3}
            color="#343B4C"
            bg={isVisa ? "#AAE2DB" : "#FEE7AC"}
            borderRadius="6px"
            display="inline-flex"
        >
            {isVisa ? "Виза" : "Доп. запрос"}
        </Text>
    )
}

export function SigningTable() {

    const columns = useMemo(
        () => [
            columnHelper.accessor("deadline", {
                header: DeadlineHeader,
                enableSorting: true

            }),
            columnHelper.accessor("document_name", {
                header: "Документ",
                enableColumnFilter: true
            }),
            columnHelper.accessor("type", {
                header: (info) => FilterHeader(info.column, info.table, "Тип"),
                cell: TypeCell,
                enableColumnFilter: true,
                filterFn: ({ original: { type } }, id, filterValues) =>
                    filterFunction({ value: type, filterValues }),
                meta: {
                    filterSelect: true
                },
            }),
            columnHelper.accessor("contract", {
                header: "Договор",
            }),
            columnHelper.accessor("project_name", {
                header: "Проект",
            }),
            columnHelper.accessor("amount", {
                header: "Сумма",
            }),
            columnHelper.accessor("diadoc_document", {
                header: DiadocHeader,
                enableColumnFilter: true
            }),
            {
                header: "",
                id: "download",
                cell: DownloadCell,
            },
        ],
        []
    );

    return (
        <Table minHeight="300px" data={signingItems} columns={columns} dblClickDisabled={true} />
    );
}