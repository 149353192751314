import { useMemo } from "react";
import {
  ColumnDef,
  Row,
  createColumnHelper,
} from "@tanstack/react-table";
import { Flex } from "@chakra-ui/react";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import { ReactComponent as EditFile } from "../../images/svg/documents/edit-file.svg";
import { formatAmount } from "../../helpers/formatAmount";
import { IEstimate } from "../../api/estimates/types";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";

type TPaymentItem = {
  id: number;
  status: string;
  sum: string;
  project: string;
  manager: string;
};

const paymentTableDataItem: TPaymentItem = {
  id: 1,
  status: "Рабочая",
  sum: "6032544.71",
  project: "ППТ 2-5 участок №1-151",
  manager: "Читаишвили Зураб Тамазович",
};

const columnHelper = createColumnHelper<TPaymentItem>();

const IdCell = () => {
  return (
    <Flex justify="center" cursor="pointer">
      <EditFile />
    </Flex>
  );
};

const handleEstimatesTableRowClick = (row: Row<IEstimate>) => {
  row.toggleSelected();
}

export function PaymentTable() {
  const columns = useMemo<Array<ColumnDef<TPaymentItem, string>>>(
    () => [
      {
        id: "id",
        cell: IdCell,
        enableHiding: false,
      },
      columnHelper.accessor("status", {
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue()),
      }),
      columnHelper.accessor("sum", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue())
      }),

      columnHelper.accessor("project", {
        header: "Проект",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("manager", {
        header: (info) => FilterHeader(info.column, info.table, "Исполнитель"),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        }
      }),
    ],
    []
  );

  return <Table rowClick={handleEstimatesTableRowClick} dblClickDisabled={true} data={Array(5).fill(paymentTableDataItem)} columns={columns} />;
}
